import request from '@/utils/request';

/**
 * 创建大会列表接口
 * @param {*} data 请求参数
 * @returns Promise
 */
export function setMeetList(data) {
  return request({
    method: 'post',
    url: '/meeting/create',
    data,
  });
}

/**
 * 获取大会列表接口
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getMeetList(params) {
  return request({
    method: 'get',
    url: '/meeting/list',
    params,
  });
}

/**
 * 获取大会页面列表接口
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getViewList(params) {
  return request({
    method: 'get',
    url: '/meeting/view/list',
    params,
  });
}

/**
 * 创建大会页面接口
 * @param {*} data 请求参数
 * @returns Promise
 */
export function setViewList(data) {
  return request({
    method: 'post',
    url: '/meeting/view/create',
    data,
  });
}


/**
 * 获取页面详情接口
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getPageData(params) {
  return request({
    method: 'get',
    url: '/meeting/modular/detail',
    params,
  });
}

/**
 * 创建页面详情接口
 * @param {*} data 请求参数
 * @returns Promise
 */
export function setPageData(data) {
  return request({
    method: 'post',
    url: '/meeting/modular/create',
    data,
  });
}

/**
 * 删除大会详情接口
 * @param {*} data 请求参数
 * @returns Promise
 */
export function delatePageData(params) {
  return request({
    method: 'get',
    url: '/meeting/delete',
    params,
  });
}

/**
 * 删除页面详情接口
 * @param {*} data 请求参数
 * @returns Promise
 */
export function delateViewData(params) {
  return request({
    method: 'get',
    url: '/meeting/view/delete',
    params,
  });
}

/**
 * 获取大会下直播列表，必须传大会id
 * 二次修改接口，可能有地方用到，不删除
 * @param {*} data 请求参数
 * @returns Promise
 */
export function geiVideoList(params) {
  return request({
    method: 'get',
    url: '/meeting/live/list',
    params,
  });
}

/**
 * 获取大会下直播列表，必须传大会id
 * 二次修改接口，可能有地方用到，不删除
 * @param {*} data 请求参数
 * @returns Promise
 */
export function geiVideoListAll(params) {
  return request({
    method: 'get',
    url: '/meeting/live/list',
    params,
  });
}

/**
 * 删除直播
 * @param {*} data 请求参数
 * @returns Promise
 */
export function delateVideo(params) {
  return request({
    method: 'get',
    url: '/meeting/live/delete',
    params,
  });
}



/**
 * 创建直播或者日程列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function createVideoList(data) {
  return request({
    method: 'post',
    url: '/meeting/live/create',
    data,
  });
}

/**
 * 获取直播详情
 * @param {*} data 请求参数
 * @returns Promise
 */
export function geiVideodetail(params) {
  return request({
    method: 'get',
    url: '/meeting/live/detail',
    params,
  });
}


/**
 * 创建直播签到
 * @param {*} data 请求参数
 * @returns Promise
 */
export function createForm(data) {
  return request({
    method: 'post',
    url: '/meeting/live/createForm',
    data,
  });
}

/**
 * 获取直播签到
 * @param {*} data 请求参数
 * @returns Promise
 */
export function geiVideoForm(params) {
  return request({
    method: 'get',
    url: '/meeting/live/form',
    params,
  });
}

/**
 * 获取签到表单按钮列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function geiFormModular(params) {
  return request({
    method: 'get',
    url: 'meeting/form/modular',
    params,
  });
}

/**
 * 获取签到表单按钮列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function refreshView(params) {
  return request({
    method: 'get',
    url: 'meeting/live/refreshView',
    params,
  });
}

/**
 * 创建视频数据
 * @param {*} data 请求参数
 * @returns Promise
 */
export function createVideoCreate(data) {
  return request({
    method: 'post',
    url: '/meeting/video/create',
    data,
  });
}

/**
 * 获取视频列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getVideoList(params) {
  return request({
    method: 'get',
    url: 'meeting/video/list',
    params,
  });
}


/**
 * 模块下获取全部视频列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getVideoModular(params) {
  return request({
    method: 'get',
    url: 'meeting/video/modular/list',
    params,
  });
}

/**
 * 根据id删除视频
 * @param {*} data 请求参数
 * @returns Promise
 */
export function DelateVideoModular(params) {
  return request({
    method: 'get',
    url: 'meeting/video/delete',
    params,
  });
}


/**
 * 表单签到用户列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getSignin(params) {
  return request({
    method: 'get',
    url: 'meeting/form/signin/list',
    params,
  });
}

/**
 * 删除表单数据
 * @param {*} data 请求参数
 * @returns Promise
 */
export function deleteSignin(params) {
  return request({
    method: 'get',
    url: 'meeting/form/signin/delete',
    params,
  });
}


/**
 * 导出表单签到用户数据
 * @param {*} data 请求参数
 * @returns Promise
 */
export function exportExcel(params) {
  return request({
    method: 'get',
    url: 'meeting/form/export/excel',
    params,
  });
}

/**
 * 获取分享数据
 * @param {*} data 请求参数
 * @returns Promise
 */
export function promotionDetail(data) {
  return request({
    method: 'post',
    url: '/meeting/promotion/detail',
    data,
  });
}

/**
 * 创建分享数据
 * @param {*} data 请求参数
 * @returns Promise
 */
export function promotionCreate(data) {
  return request({
    method: 'post',
    url: '/meeting/promotion/create',
    data,
  });
}

/**
 * 获取大会详情
 * @param {*} data 请求参数
 * @returns Promise
 */
export function meetingDetail(params) {
  return request({
    method: 'get',
    url: '/meeting/detail',
    params,
  });
}

/**
 * 模块获取视频接口
 * @param {*} data 请求参数
 * @returns Promise
 */
export function modular_videoList(params) {
  return request({
    method: 'get',
    url: '/meeting/modular/live/list',
    params,
  });
}

/**
 * 创建直播多频道
 * @param {*} data 请求参数
 * @returns Promise
 */
export function channelCreate(data) {
  return request({
    method: 'post',
    url: '/meeting/live/channel/crete',
    data,
  });
}

/**
 * 获取多频道
 * @param {*} data 请求参数
 * @returns Promise
 */
export function channelGet(params) {
  return request({
    method: 'get',
    url: '/meeting/live/channel/list',
    params,
  });
}

/**
 * 创建tab
 * @param {*} data 请求参数
 * @returns Promise
 */
export function tabCreate(data) {
  return request({
    method: 'post',
    url: '/meeting/live/tab/create',
    data,
  });
}

/**
 * 获取tab
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getTab(params) {
  return request({
    method: 'get',
    url: '/meeting/live/tab/list',
    params,
  });
}


/**
 * 导入专家任务接口
 * @param {*} data 请求参数
 * @returns Promise
 */
export function uploadExcel(data) {
  return request({
    method: 'post',
    url: '/meeting/task/import/excel',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}


/**
 * 获取专家任务接口
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getTaskList(data) {
  return request({
    method: 'post',
    url: '/meeting/task/list',
    data,
  });
}

/**
 * 获取角色身份
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getIdentity(params) {
  return request({
    method: 'get',
    url: '/meeting/task/identity/list',
    params,
  });
}

/**
 * 获取任务详情
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getTaskDetail(params) {
  return request({
    method: 'get',
    url: '/meeting/task/detail',
    params,
  });
}

/**
 * 获取任务环节
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getTaskModule(params) {
  return request({
    method: 'get',
    url: '/meeting/task/module/list',
    params,
  });
}

/**
 * 保存创建任务
 * @param {*} data 请求参数
 * @returns Promise
 */
export function createTask(data) {
  return request({
    method: 'post',
    url: '/meeting/task/create',
    data,
  });
}


/**
 * 获取专家任务基本数据
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getTaskConfig(params) {
  return request({
    method: 'get',
    url: '/meeting/task/config/detail',
    params,
  });
}
/**
 * 保存专家任务基本数据
 * @param {*} data 请求参数
 * @returns Promise
 */
export function createTaskConfig(data) {
  return request({
    method: 'post',
    url: '/meeting/task/config/create',
    data,
  });
}

/**
 * 删除任务
 * @param {*} data 请求参数
 * @returns Promise
 */
export function delateTask(params) {
  return request({
    method: 'get',
    url: '/meeting/task/delete',
    params,
  });
}

/**
 * 获取校对库
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getTaskCheck(params) {
  return request({
    method: 'get',
    url: '/meeting/task/check',
    params,
  });
}

/**
 * 获取专家库列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getSpecialistDetail(params) {
  return request({
    method: 'get',
    url: '/meeting/specialist/detail',
    params,
  });
}

/**
 * 获取医院列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getHospitalList(params) {
  return request({
    method: 'get',
    url: '/meeting/specialist/hospital/list',
    params,
  });
}

/**
 * 专家库列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getSpecialistList(data) {
  return request({
    method: 'post',
    url: '/meeting/specialist/list',
    data,
  });
}


/**
 * 专家库列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function downloadTemplate(params) {
  return request({
    method: 'get',
    url: '/meeting/task/template/download',
    params,
  });
}

/**
 * 导入专家任务-检查医生信息
 * @param {*} data 请求参数
 * @returns Promise
 */
export function checkSpecialist(data) {
  return request({
    method: 'post',
    url: '/meeting/task/import/check/specialist',
    data,
  });
}

/**
 * 创建或编辑专家信息
 * @param {*} data 请求参数
 * @returns Promise
 */
export function createSpecialist(data) {
  return request({
    method: 'post',
    url: '/meeting/specialist/create',
    data,
  });
}

/**
 * 导出缺失的专家任务
 * @param {*} data 请求参数
 * @returns Promise
 */
export function downloadErr(params) {
  return request({
    method: 'get',
    url: '/meeting/task/export/absence/specialist',
    params,
  });
}

/**
 * 获取表单组件列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function formModular(params) {
  return request({
    method: 'get',
    url: '/meeting/form/modular',
    params,
  });
}

/**
 * 获取表单标志列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function formMark(params) {
  return request({
    method: 'get',
    url: '/meeting/form/mark/list',
    params,
  });
}

/**
 * 签到用户列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function formSignin(params) {
  return request({
    method: 'get',
    url: '/meeting/form/signin/list',
    params,
  });
}

/**
 * 获取素材下视频列表（包含视频，直播，日程）
 * @param {*} data 请求参数
 * @returns Promise
 */
export function materialAll(params) {
  return request({
    method: 'get',
    url: '/material/live/all/list',
    params,
  });
}

/**
 * 确定导入专家
 * @param {*} data 请求参数
 * @returns Promise
 */
export function specialistUp(data) {
  return request({
    method: 'post',
    url: '/meeting/specialist/import/excel',
    data,
  });
}

/**
 * 获取模板地址
 * @param {*} data 请求参数
 * @returns Promise
 */
export function specialist_tmp(params) {
  return request({
    method: 'get',
    url: '/meeting/specialist/template/download',
    params,
  });
}

/**
 * 创建图文
 * @param {*} data 请求参数
 * @returns Promise
 */
export function createDocument(data) {
  return request({
    method: 'post',
    url: '/material/document/create',
    data,
  });
}

/**
 * 获取图文列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getDocumentList(params) {
  return request({
    method: 'get',
    url: '/material/document/list',
    params,
  });
}

/**
 * 组件获取图文列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getModularDocumentList(params) {
  return request({
    method: 'get',
    url: '/meeting/modular/document/list',
    params,
  });
}


/**
 * 获取图文详情
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getDocumentDet(params) {
  return request({
    method: 'get',
    url: '/material/document/detail',
    params,
  });
}

/**
 * 获取用户信息
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getUserinfo(params) {
  return request({
    method: 'get',
    url: '/userinfo/detail/get',
    params,
  });
}

/**
 * 修改用户信息
 * @param {*} data 请求参数
 * @returns Promise
 */
export function createUserinfo(data) {
  return request({
    method: 'post',
    url: '/userinfo/update/data',
    data,
  });
}

/**
 * 修改账户密码
 * @param {*} data 请求参数
 * @returns Promise
 */
export function createUserLogin(data) {
  return request({
    method: 'post',
    url: '/userinfo/update/password',
    data,
  });
}

/**
 * 获取用户信息
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getHomeData(params) {
  return request({
    method: 'get',
    url: '/meeting/homepage/index',
    params,
  });
}


/**
 * 获取用户信息
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getHomeEcharts(params) {
  return request({
    method: 'get',
    url: '/meeting/homepage/meeting/view',
    params,
  });
}

/**
 * 获取门户下图文列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function getMeetDocument(params) {
  return request({
    method: 'get',
    url: '/meeting/document/list',
    params,
  });
}

/**
 * 刷新直播浏览量
 * @param {*} data 请求参数
 * @returns Promise
 */
export function addRefresh(data) {
  return request({
    method: 'post',
    url: '/meeting/refresh/live/data',
    data,
  });
} 

/**
 * 获取刷新列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function refreshList(params) {
  return request({
    method: 'get',
    url: '/meeting/refresh/live/list',
    params,
  });
}


/**
 * 停止刷新
 * @param {*} data 请求参数
 * @returns Promise
 */
export function overRefresh(params) {
  return request({
    method: 'get',
    url: '/meeting/refresh/live/stop',
    params,
  });
}

/**
 * 删除专家
 * @param {*} data 请求参数
 * @returns Promise
 */
export function specialistDelete(params) {
  return request({
    method: 'get',
    url: '/meeting/specialist/delete',
    params,
  });
}

/**
 * 导出文件列表
 * @param {*} data 请求参数
 * @returns Promise
 */
export function exportFileList(params) {
  return request({
    method: 'get',
    url: '/meeting/form/export/fileList',
    params,
  });
}

