<template>
  <div class="user">
    <div class="title">
      <span class="meet_title"><img width="24px" src="@/assets/icon_门户列表@2x (3).png" alt=""> 个人中心</span>
      <div style="flex:1"></div>
    </div>
    <a-tabs type="card">
      <a-tab-pane key="1" tab="个人信息">
        <accountInformation :formData='form'></accountInformation>
      </a-tab-pane>
      <a-tab-pane key="2" tab="账户密码">
        <changePassword :formData='form'></changePassword>
      </a-tab-pane>
    </a-tabs>
  </div>
</template> 
<script>
import { mapGetters, mapActions } from "vuex";
import { createUserinfo } from '@/api/meetApi.js';
import accountInformation from './components/accountInformation.vue';
import changePassword from './components/changePassword.vue';
export default {
  components: {
    accountInformation,
    changePassword
  },
  data() {
    return {
      form: {}
    }
  },
  computed: {
    ...mapGetters(["userinfo"]),
  },
  created() {
    this.SET_WEB_ROUTER(['个人中心'])
    this.form = JSON.parse(JSON.stringify(this.userinfo))
  },
  methods: {
    callback(key) {
      console.log(key);
    },
  },
};
</script>
<style lang="less" scoped>
.user {
  /* // max-width: 1400px; */
  margin: auto;
  background-color: #fff;
  padding: 24px;
  margin: 0 12px 12px 12px;
}
.title {
  display: flex;
  margin-bottom: 24px;
  .onSearchBox {
    :deep(.ant-btn-primary) {
      background: #f5f7fa;
      border: 1px solid #e5e6eb;
      color: #7e8081;
      box-shadow: unset;
    }
    width: 312px;
    margin-right: 24px;
  }
  .meet_title {
    img {
      margin-right: 8px;
      position: relative;
      top: 1px;
    }
    display: flex;
    align-items: center;
  }
  span {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 30px;
    text-align: left;
    font-style: normal;
  }
}
</style>
