<template>
<!-- 此处为素材视频(videoList)和图文(articleList)的出口 -->
  <router-view />
</template>

<script>
export default {

}
</script>

<style>
</style>