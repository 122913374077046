<template>
  <div class="menu">
    <Modal ref="modal" @getList='getList' @keyword='keyword' />
    <Preview ref="preview" />
    <a-table :loading="loading" :pagination="ipagination" @change="handlePageChange" :rowKey="record=>record.id"
      :columns="columns" :data-source="data">

      <span slot="meeting_name" slot-scope="text, record">
        <span class="meeting_name">{{ record.meeting_name?record.meeting_name: '未填' }} </span>
      </span>

      <span slot="begin_time" slot-scope="text, record">
        <span class="begin_time">{{ record.begin_time?record.begin_time+' - '+record.end_time: '未填' }} </span>
      </span>

      <span slot="action" slot-scope="text, record">
        <a class="action_btn" @click='goUrl(record)'> 配置 </a>
        <a-divider type="vertical" />
        <a class="action_btn" @click='change(record)'> 推广 </a>
        <a-divider type="vertical" />
        <a class="action_btn" @click="delate(record)"> 删除 </a>
      </span>
    </a-table>
  </div>
</template>
<script>
import { getMeetList, delatePageData } from "@/api/meetApi";
import Modal from "./components/Modal.vue";
import Preview from "@/components/Preview.vue";
const columns = [
  {
    title: "名称",
    dataIndex: "meeting_name",
    scopedSlots: { customRender: "meeting_name" },
    key: "meeting_name",
    align: "left",
  },
  {
    title: "周期",
    dataIndex: "begin_time",
    scopedSlots: { customRender: "begin_time" },
    key: "begin_time",
    align: "left",
  },
  // {
  //   title: "结束时间",
  //   dataIndex: "end_time",
  //   scopedSlots: { customRender: "end_time" },
  //   key: "end_time",
  //   align: "left",
  // },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "left",
  },
];

export default {
  components: { Modal, Preview },
  data() {
    return {
      loading: false,
      data: [],
      columns,
      keywordStr: '',
      //分页参数
      ipagination: {
        size: "large",
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ["10", "20", "30"], //可选的页面显示条数
        // onChange: page => handlePageChange(page),
        showTotal: (total, range) => {
          return "共 " + total + " 条";
        }, //展示每页第几条至第几条和总数
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器
        showQuickJumper: true, //是否可以快速跳转至某页
        showSizeChanger: true, //是否可以改变pageSize
      },
    };
  },
  created() {
    this.getList();
    this.SET_WEB_ROUTER(['门户列表', '门户列表'])
  },
  methods: {
    async getList(page) {
      this.loading = true;
      let data = await getMeetList(page);
      this.data = data.payload.data;
      this.ipagination.total = data.payload.page.total;
      this.ipagination.pageSize = data.payload.page.per_page;
      this.loading = false;
    },
    keyword(data) {
      this.keywordStr = data
      this.getList({ page: 1, per_page: this.ipagination.pageSize, keyword: data });
    },
    change(data) {
      console.log(data);
      this.$refs.preview.show('meeting', data.id);
    }, //表格改变时触发
    handlePageChange(page) {
      console.log(page);
      this.ipagination = page;
      this.getList({ page: page.current, per_page: page.pageSize, keyword: this.keywordStr });
    },
    delate(data) {
      var _this = this;
      this.$confirm({
        title: "确认删除吗？",
        content: "删除后数据不可恢复！",
        okText: "确认",
        cancelText: "取消",
        async onOk() {
          let str = await delatePageData({ id: data.id })
          _this.$message.success('删除成功！')
          _this.getList()
        },
        onCancel() { },
      });
    },
    goUrl(data) {
      this.$router.push('/menuList?meeting_id=' + data.id)
      console.log(data)
    }
  },
};
</script>

<style lang="less" scoped>
.menu {
  // max-width: 1400px;
  margin: auto;
  background-color: #fff;
  padding: 24px;
  margin: 0 12px 12px 12px;
}
.meeting_name {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
  text-align: left;
  font-style: normal;
}
.begin_time {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
  text-align: left;
  font-style: normal;
}
.action_btn {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #1890ff;
  line-height: 20px;
  text-align: right;
  font-style: normal;
}
:deep(.ant-table-thead) {
  height: 48px;
  tr {
    th {
      background: #f3f6f9;
      height: 48px;
      padding: 0 16px;
    }
  }
}
:deep(.ant-table-tbody) {
  height: 48px;
  tr {
    td {
      height: 59px;
    }
  }
}
</style>