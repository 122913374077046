<template>
  <div class="menu">
    <Modal ref="modal" @addList='addList' @keyword='keyword' />
    <a-table @change="handleTableChange" :pagination="pagination" :rowKey='record=>record.id' :columns="columns"
      :data-source="data">

      <span class="menu_name_box" slot="video_title" slot-scope="text, record">
        <div class="list_img_title">
          <img v-if="!record.cover_img_path" width="150px" height="85px" src="@/assets/novideo.png" alt="">
          <img v-if="record.cover_img_path" width="150px" height="85px" :src="record.cover_img_path" alt="">
          <span class="menu_name">{{record.title}}</span>
        </div>
      </span>

      <span slot="publish_time" slot-scope="text, record">
        <span v-if="!record.publish_time">未填</span>
        <span v-if="record.publish_time">{{ record.publish_time|time }} </span>
      </span>

      <span slot="action" slot-scope="text, record">
        <a @click='workPlace(record)'> 编辑 </a>
        <a-divider type="vertical" />
        <a @click="delate(text)"> 删除 </a>
      </span>
    </a-table>
  </div>
</template> 
 
<script>

import { createDocument, getDocumentList, getDocumentDet } from '@/api/meetApi.js';

import Modal from "./components/Modal.vue";
const columns = [
  {
    title: "图文标题",
    dataIndex: "video_title",
    scopedSlots: { customRender: "video_title" },
    key: "video_title",
    // width: 350,
    align: "left",
  },
  {
    title: "发布时间",
    key: "publish_time",
    scopedSlots: { customRender: "publish_time" },
    align: "left", width: 350,
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "left", width: 150,
  },
];

export default {
  name: "videoList",
  components: { Modal },
  data() {
    return {
      keywordStr: '',
      data: [],
      columns,
      pagination: {
        size: "large",
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ["10", "20", "30"], //可选的页面显示条数
        // onChange: page => handlePageChange(page),
        showTotal: (total, range) => {
          return "共 " + total + " 条";
        }, //展示每页第几条至第几条和总数
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器
        showQuickJumper: true, //是否可以快速跳转至某页
        showSizeChanger: true, //是否可以改变pageSize
      }
    };
  },
  methods: {
    addList(data) {
      this.getVideList({
        page: this.pagination.current
      })
    },
    change(data) {
      this.$refs.modal.change(data);
    },
    workPlace(data) {
      this.$router.push("/articleSet?id=" + data.id);
    },
    keyword(data) {
      this.keywordStr = data
      this.getVideList({ page: 1, per_page: this.pagination.pageSize, keyword: data });
    },
    async delate(msg) {
      var _this = this;

      this.$confirm({
        title: "确认删除吗？",
        content: "删除后数据不可恢复！",
        okText: "确认",
        cancelText: "取消",
        async onOk() {
          let data = await delateVideo({
            live_id: msg.id
          })
          _this.getVideList({
            page: _this.pagination.current
          })
        },
        onCancel() { },
      });
    },
    async getVideList(page) {
      this.SET_WEB_ROUTER(['素材', '图文列表'])

      let data = await getDocumentList(page)
      console.log(data)
      this.data = data.payload.data
      // this.pagination.current = data.payload.page.current_page
      this.pagination.total = data.payload.page.total
    },
    handleTableChange(data) {
      this.pagination.current = data.current
      this.getVideList({
        page: this.pagination.current
      })
    }
  },
  created() {
    this.getVideList({ meeting_id: 1 })
  }
};
</script>

<style lang="less" scoped>
:deep(.ant-table-row td) {
  padding: 8px 8px;
}
.list_img_title {
  display: flex;
  width: 100%;
  align-items: center;
  .menu_name_box {
    display: flex;
  }

  .menu_name {
    flex: 1;
    display: inline-block;
    // width: 130px;
    text-align: left;
    height: 100%;
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    line-height: 20px;
  }
  img {
    width: 106px;
    height: 60px;
    border-radius: 4px;
  }
}
.menu {
  // max-width: 1400px;
  margin: auto;
  background-color: #fff;
  padding: 24px;
  margin: 0 12px 12px 12px;
}
.dian {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}
.dian1 {
  background-color: green;
}
.dian2 {
  background-color: red;
}
.dian3 {
  background-color: dimgrey;
}
</style>