<template>
  <div class="helpPage">
    <div class="helpPage_L">
      <a-tree :tree-data="treeData" @select='selectFn' show-icon default-expand-all
        :default-selected-keys="[selectKeys]"></a-tree>
    </div>
    <div class="helpPage_R">
      <pageA @changeHome='changeHome' v-if="selectKeys=='0-0-0'"></pageA>
      <pageB @changeHome='changeHome' v-if="selectKeys=='0-0-1'"></pageB>
      <pageC @changeHome='changeHome' v-if="selectKeys=='1-0-0'"></pageC>
      <pageD @changeHome='changeHome' v-if="selectKeys=='1-0-1'"></pageD>
      <pageE @changeHome='changeHome' v-if="selectKeys=='1-0-2'"></pageE>
      <pageF @changeHome='changeHome' v-if="selectKeys=='1-0-3'"></pageF>
      <pageG @changeHome='changeHome' v-if="selectKeys=='1-0-4'"></pageG>
      <pageH @changeHome='changeHome' v-if="selectKeys=='1-0-5'"></pageH>
      <pageI @changeHome='changeHome' v-if="selectKeys=='1-0-6'"></pageI>
      <pageJ @changeHome='changeHome' v-if="selectKeys=='1-0-7'"></pageJ>
    </div>
  </div>
</template>

<script> 
import Vue from "vue";
export default {
  data() {
    return {
      selectKeys: '0-0-0',
      treeData: [
        {
          title: '新手指南',
          key: '0-0-0',
          children: [
            { title: '注册登录', key: '0-0-0', },
            { title: '首页', key: '0-0-1', },
          ],
        },
        {
          title: '门户',
          key: '1-0-0',
          children: [
            { title: '门户列表', key: '1-0-0', },
            { title: '基本', key: '1-0-1', },
            { title: '页面', key: '1-0-2', },
            { title: '直播', key: '1-0-3', },
            // { title: '图文', key: '1-0-4', },
            // { title: '表单数据', key: '1-0-5', },
            // { title: '专家任务', key: '1-0-6', },
            // { title: '刷新', key: '1-0-7', },
          ],
        },
        // {
        //   title: '素材',
        //   key: '2-0-0',
        //   children: [
        //     { title: '视频', key: '2-0-0', },
        //     { title: '图文', key: '2-0-1', },
        //   ],
        // },
        // {
        //   title: '专家库',
        //   key: '3-0',
        //   // children: [
        //   //   { title: '专家', key: '3-0-0', }, 
        //   // ],
        // },
        // {
        //   title: '个人中心',
        //   key: '4-0-0',
        //   children: [
        //     { title: '个人信息', key: '4-0-0', },
        //     { title: '账户密码', key: '4-0-1', },
        //   ],
        // },
      ],
    };
  },
  created() {
    // // 引入./components/workplace中所有组件
    const requireComponents1 = require.context("./components", true, /\.vue/);
    requireComponents1.keys().forEach((fileName) => {
      const reqCom = requireComponents1(fileName);
      const reqComName = reqCom.name || fileName.replace(/\.\/(.*)\.vue/, "$1");
      Vue.component(reqComName, reqCom.default || reqCom);
    });
  },
  methods: {
    onSelect(selectedKeys, info) {
      console.log('selected', selectedKeys, info);
    },
    onCheck(checkedKeys, info) {
      console.log('onCheck', checkedKeys, info);
    },
    selectFn(selectedKeys, e) {
      this.selectKeys = selectedKeys[0];
      console.log(selectedKeys, e);
    },
    changeHome(str) {
      this.selectKeys = str;
    }
  },
};
</script>

<style lang="less" scoped>
.helpPage {
  display: flex;
  background-color: #f9fafb;
  min-height: 100vh;
  padding: 24px;
}
.helpPage_L {
  width: 250px;
}
.helpPage_R {
  flex: 1;
  background-color: #fff;
  padding: 24px;
}
</style>