<template>
  <div class="form">

    <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" :label-col="labelCol">
      <a-form-model-item key="">
        <p class="title">账号修改</p>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 19, offset: 1 }" label="当前绑定手机号：">
        <span v-for="(item,index) in formData.mobile" :key="index">
          <span v-show="index<=2||index>=7">{{item}}</span>
          <span v-show="index>2&&index<7">*</span>
        </span>
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{ span: 19, offset: 1 }" label="新手机号：" prop="new_phone">
        <a-input v-model="ruleForm.new_phone" type="text" autocomplete="off" />
      </a-form-model-item>

      <a-form-model-item style="position: relative;" :wrapper-col="{ span: 19, offset: 1 }" label="验证码：" prop="code">
        <a-input v-model="ruleForm.code" autocomplete="off" />
        <a-button class="getStr" type="primary">
          获取验证码
        </a-button>
      </a-form-model-item>

      <a-form-model-item>
        <p class="title">密码修改</p>
      </a-form-model-item>
      <a-form-model-item style="position: relative;" :wrapper-col="{ span: 19, offset: 1 }" label="旧密码："
        prop="old_password">
        <a-input v-model="ruleForm.old_password" :type="!showPass?'password':'text'" autocomplete="off" />
        <img v-if="!showPass" @click='showPassFn' class="pointer showPass" src="@/assets/view@2x (1).png" alt="">
        <img v-if="showPass" @click='showPassFn' class="pointer showPass" src="@/assets/view@2x.png" alt="">
      </a-form-model-item>
      <a-form-model-item style="position: relative;" :wrapper-col="{ span: 19, offset: 1 }" label="新密码："
        prop="new_password">
        <a-input v-model="ruleForm.new_password" :type="!showPass?'password':'text'" autocomplete="off" />
        <img v-if="!showPass" @click='showPassFn' class="pointer showPass" src="@/assets/view@2x (1).png" alt="">
        <img v-if="showPass" @click='showPassFn' class="pointer showPass" src="@/assets/view@2x.png" alt="">
      </a-form-model-item>
      <a-form-model-item style="position: relative;" :wrapper-col="{ span: 19, offset: 1 }" label="确认新密码："
        prop="checkPass">
        <a-input v-model="ruleForm.checkPass" :type="!showPass?'password':'text'" autocomplete="off" />
        <img v-if="!showPass" @click='showPassFn' class="pointer showPass" src="@/assets/view@2x (1).png" alt="">
        <img v-if="showPass" @click='showPassFn' class="pointer showPass" src="@/assets/view@2x.png" alt="">
      </a-form-model-item>
    </a-form-model>

    <div class="add_formBox_sendBox">
      <a-button @click="resetForm('ruleForm')" class="add_formBox_sendBox_qx">取消</a-button>
      <a-button @click="submitForm('ruleForm')" type="primary">
        保存
      </a-button>
    </div>
  </div>
</template>

<script>
import { createUserinfo, createUserLogin } from '@/api/meetApi.js';

export default {
  props: {
    formData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      showPass: false,
      ruleForm: {
        old_password: "",
        new_password: "",
        checkPass: "",
        code: '',
        new_phone: ''
      },
      rules: {
      },
      labelCol: { span: 4 },
    };
  },
  methods: {
    async submitForm(formName) {
      
      if (!this.ruleForm.new_phone) {
        return this.$message.error("请填写手机号！");
      }
      let reg = /^1[34578]\d{9}$/;
      if (!reg.test(this.ruleForm.new_phone)) {
        return this.$message.error("请填写正确的手机号！");
      }
      if (!this.ruleForm.code) {
        return this.$message.error("请填验证码！");
      }
      if (this.ruleForm.old_password || this.ruleForm.new_password || thi.ruleForm.checkPass) {
        if (!this.ruleForm.old_password || !this.ruleForm.new_password || !this.ruleForm.checkPass) {
          this.$message.error("请将旧密码，新密码，确认新密码填写完整！");
        } else {
          if (this.ruleForm.new_password !== this.ruleForm.checkPass) {
            return this.$message.error("新密码和确认新密码不一致！");
          }
          if (this.ruleForm.new_password !== this.ruleForm.old_password) {
            return this.$message.error("新密码和旧密码不能相同！");
          }
          if (this.ruleForm.new_password.length < 6) {
            return this.$message.error("新密码长度不能小于6！");
          }
        }
      }

      this.ruleForm.id = this.formData.id
      let data = await createUserLogin(this.ruleForm);
      this.$message.success("修改完成，请重新登录！");
      setTimeout(async () => {
        await this.$store.dispatch("loginInfo/logOut");
      }, 1000);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    showPassFn() {
      this.showPass = !this.showPass;
    }
  },
};
</script>
 
<style scoped>
.form .getStr {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 28px !important;
}
.add_formBox_sendBox {
  flex: 1;
  border-top: 1px solid #e3e4e8;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  position: fixed;
  width: calc(100% - 204px);
  bottom: 12px;
  left: 192px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.05);
  text-align: center;
  align-items: center;
}
.add_formBox_sendBox_qx {
  margin-right: 10px;
}
.form {
  padding-top: 8px;
  width: calc(480px + 148px);
  max-width: 1024px;
  padding-bottom: 70px;
  min-height: calc(100vh - 235px);
}
.title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
.showPass {
  width: 16px;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>