<template>
  <div class="doctorChange">
    <a-drawer :destroyOnClose='true' class="doctorChangeForm" :width="500" placement="right" :closable='false'
      :maskClosable='false' :visible="visible" :after-visible-change="afterVisibleChange" @close="onClose">
      <div class="DC_itle">
        <span v-if="!form.id">新增</span>
        <span v-if="form.id">编辑</span>
        <img @click='onClose' class="pointer" width="16px" src="@/assets/auto_img/icon_关闭@2x.png" alt="">
      </div>

      <div class="DC__form">

        <div class="add_formBox_item mb20">
          <div class="add_formBox_name"><span :class="{required: form.name}" class="add_formBox_require">*</span>姓名
          </div>
          <div class="add_formBox_inp">
            <a-input v-model="form.name" placeholder="请输入" />
          </div>
        </div>

        <div class="add_formBox_item mb20">
          <div class="add_formBox_name">
            <!-- <span :class="{required: form.name}" class="add_formBox_require">*</span> -->
            专家照片
          </div>
          <div class="add_formBox_inp">
            <div class="doctor_img">
              <upload :image='form.head_path' @uploadOk='uploadList' :showDelate='true' @delateDate='uploadListDelate'
                :width="'70px'" :height="'70px'"> </upload>
            </div>
          </div>
        </div>

        <div class="add_formBox_item mb20">
          <div class="add_formBox_name">
            <span :class="{required: form.hospital_name}" class="add_formBox_require">*</span>
            医院
          </div>
          <div class="add_formBox_inp searchDownF">
            <a-select mode="SECRET_COMBOBOX_MODE_DO_NOT_USE" v-model="form.hospital_name" style="width: 100%"
              placeholder="请输入或者选择">
              <a-select-option v-for="(i,index) in hospitalList" :key="index" :value='i.hospital_name'>
                {{i.hospital_name}}
              </a-select-option>
            </a-select>

          </div>
        </div>

        <div class="add_formBox_item mb20">
          <div class="add_formBox_name"><span :class="{required: form.department}"
              class="add_formBox_require">*</span>科室
          </div>
          <div class="add_formBox_inp">
            <a-input v-model="form.department" placeholder="请输入" :maxLength='100'
              :suffix="form.department?form.department.length+'/100':'0/100'" />
          </div>
        </div>

        <div class="add_formBox_item mb20">
          <div class="add_formBox_name"><span :class="{required: form.title}" class="add_formBox_require">*</span>职称
          </div>
          <div class="add_formBox_inp">
            <a-input v-model="form.title" placeholder="请输入" :maxLength='100'
              :suffix="form.title?form.title.length+'/100':'0/100'" />
          </div>
        </div>

        <div class="add_formBox_item mb20">
          <div class="add_formBox_name">
            <!-- <span :class="{required: form.task_title}" class="add_formBox_require">*</span> -->
            简介
          </div>
          <div class="add_formBox_inp">
            <a-input v-model="form.content" type='textarea' :auto-size="{ minRows: 14, maxRows: 16 }" placeholder="请输入"
               />
          </div>
        </div>

      </div>
      <div class="dc_btn">
        <a-button @click="onClose">取消</a-button>
        <a-button @click="sendForm" type="primary">
          确定
        </a-button>
      </div>

    </a-drawer>
  </div>
</template>
<script>
import { getSpecialistDetail, getHospitalList, getSpecialistList, createSpecialist } from "@/api/meetApi";
import upload from '@/components/upload.vue';
import moment from "moment";
export default {
  components: { upload },
  data() {
    return {
      form: {
        "name": "",
        "head": '',
        "head_path": "",
        "hospital_name": "",
        "title": '',
        "department": '',
        "content": '',
        address: ''
      },
      hospitalList: [],
      visible: false,
    };
  },
  created() {
    this.getHospitalListFN()
  },
  methods: {
    moment,
    async sendForm() {
      console.log(this.form)
      for (const key in this.form) {
        if (!this.form[key] && key != 'address' && key != 'head' && key != 'head_path'&& key != 'content') {
          return this.$message.error('请全部填写完整！');
        }
      }
      let data = await createSpecialist(this.form)
      this.$message.success('添加成功！')
      this.$emit('addOk')
      this.onClose()
    },
    uploadList(str) {
      if (str.status == 1) {
        this.form.head_path = str.payload.path
        this.form.head = str.payload.id
      } else {
        alert('上传失败，请刷新重试！')
      }
    },
    async getHospitalListFN() {
      let data = await getHospitalList()
      this.hospitalList = data.payload.data
      console.log(data)
    },
    uploadListDelate() {
      this.form.head_path = ''
      this.form.head = ''
    },
    afterVisibleChange(val) {
      console.log('visible', val);
    },
    async showDrawer(id) {
      if (id) {
        let data = await getSpecialistDetail({ id: id })
        this.form = data.payload.data
      }
      console.log(this.form)
      this.visible = true;
    },
    onClose() {
      this.visible = false;
      this.clearFN()
    },
    clearFN() {
      for (const key in this.form) {
        this.form[key] = ''
      }
    } 
  },
};
</script>
<style lang="less" scoped>
.doctor_img {
  width: 100px;
  height: 100px;
}
.dc_btn {
  width: 100%;
  position: absolute;
  border-top: 1px solid #e3e4e8;
  height: 64px;
  bottom: 0;
  button {
    margin-left: 12px;
  }
  padding-right: 20px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: right;
  background: #ffffff;
}
.searchDown {
  position: absolute;
  right: 8px;
  width: 14px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.date_flex {
  display: flex;
  align-items: center;
  .zhi {
    font-weight: 500;
    font-size: 14px;
    color: #222222;
    margin: 0 12px;
  }
}
.date_icon {
  img {
    position: absolute;
    color: #d9d9d9;
    z-index: 1;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 12px;
    width: 12px;
  }
}
.DC_itle {
  height: 46px;
  background: #f9fafd;
  padding: 13px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  text-align: left;
}
.DC__form {
  padding: 20px;
  flex: 1;
  padding-bottom: 70px;
}
.required{
  color: green;
}
</style>
<style>
.ant-drawer-body {
  padding: 0;
}
</style>

<style>
.date_flex .ant-time-picker {
  flex: 1;
}
.date_flex .ant-time-picker-icon {
  left: 11px;
  right: unset;
}
.date_flex .ant-time-picker-input {
  padding-left: 38px;
}
.date_flex .ant-time-picker-clear {
  left: 11px;
  right: unset;
}
.doctorChangeForm {
}
.doctorChangeForm .ant-input-search-button,
.doctorChangeForm .ant-select-selection--single,
.doctorChangeForm .ant-input {
  height: 36px;
  font-weight: 400;
  font-size: 14px;
}

.doctorChangeForm .ant-select-selection__rendered {
  line-height: 36px;
  font-weight: 400;
  font-size: 14px;
}

.doctorChangeForm .ant-select-lg .ant-select-selection__rendered {
  line-height: 40px;
  font-weight: 400;
  font-size: 14px;
}

.doctorChangeForm .ant-select-lg .ant-select-selection--single,
.doctorChangeForm .ant-input-lg {
  height: 40px;
  font-weight: 400;
  font-size: 14px;
}
.doctorChangeForm .ant-btn {
  height: 36px;
}

.searchDownF .ant-select-selection__rendered {
  padding-right: 24px;
}
.searchDownF .ant-select-arrow {
  display: block !important;
}
</style>