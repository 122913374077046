<template>
  <div id="home1" class="pageA">
    <div class="pageA_title">注册登录</div>
    <hr>
    <div class="pageA_data">
      <div class="pageA_left">
        <div class="pageA_titleB">数会云官网</div>
        <div class="pageA_Content"><a href="https://www.cndmcloud.com/setmeet/#/login" target="_blank"
            rel="noopener noreferrer">https://www.cndmcloud.com/setmeet/#/login</a></div>
        <div class="pageA_titleB">操作步骤</div>
        <div class="pageA_Content">第一步：注册账户按要求填写手机号验证码以及密码。</div>
        <div class="pageA_Img"><img src="@/assets/help/001 (1).jpg" alt=""></div>
        <div id="home2" class="pageA_Content">第二步：点击官网右立即按钮，两种方式：账号密码、手机短信验证登录。</div>
        <div class="pageA_Img"><img src="@/assets/help/001 (2).jpg" alt=""></div>
      </div>
      <div class="pageA_right">
        <a-anchor>
          <p class="anchor_p">本页目录</p>
          <a-anchor-link href="#home1" title="注册" />
          <a-anchor-link href="#home2" title="登录" />
        </a-anchor>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.pageA_data {
  display: flex;
  .pageA_left {
    flex: 1;
  }
  .pageA_right {
    width: 300px;
    padding: 24px;
  }
}
.anchor_p {
  font-size: 16px;
  color: #000;
  background-color: #fff;
  padding-left: 20px;
  position: relative;
  left: -10px;
  margin-top: 20px;
}
.pageA_title {
  font-size: 30px;
  padding: 8px 0;
}
.pageA_titleB {
  margin: 30px 0;
  font-size: 20px;
}
.pageA_Content {
  margin: 30px 0;
  font-size: 14px;
  line-height: 17px;
}
.pageA_Img {
  margin: 20px 0;
  img {
    width: 100%;
  }
}
.pageA_Bgc_content {
  padding: 12px;
  margin: 24px 0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 17px;
  background-color: #f3f4f6;
}
.pageA_Img_flex {
  display: flex;
}
.pageA_Img_flexItem {
  flex: 1;
}
.pageA_Img_flexItem:first-child {
  margin-right: 10px;
}
</style>