<template>
  <div class="addView">
    <a-table @change="handleTableChange" :pagination="pagination" :loading='loading' :rowKey='record=>record.id'
      :columns="columns" :data-source="data">

      <span class="menu_name_box" slot="video_title" slot-scope="text, record">
        <div class="list_img_title">
          <img v-if="!record.cover_path" width="150px" height="85px" src="@/assets/novideo.png" alt="">
          <img v-if="record.cover_path" width="150px" height="85px" :src="record.cover_path" alt="">
          <span class="menu_name">{{record.title}}</span>
        </div>
      </span>

      <span class="centerBox" slot="type" slot-scope="text, record">
        <!-- <span class="page_type">浏览量：{{record.view}}</span> -->
        <span>{{record.view}}</span>
      </span>

      <span class="centerBox" slot="setType" slot-scope="text, record">
        <span class="page_setType"><a-input type='number' :disabled="record.refresh_live_task.status==1"
            class="page_setTypeInp" v-model="record.refresh_live_task.target_num" placeholder="请输入" /></span>
      </span>

      <span class="centerBox" slot="status" slot-scope="text, record">
        <span class="status status_wks" v-if="record.status==0"><img src="@/assets/auto_img/wks.png" alt="">
          未开始</span>
        <span class="status status_zbz" v-if="record.status==1"><img src="@/assets/auto_img/zbz.png" alt="">
          直播中</span>
        <span class="status status_yjs" v-if="record.status==2"><img src="@/assets/auto_img/yjs.png" alt="">
          已结束</span>
      </span>

      <span class="centerBox" slot="begin_time" slot-scope="text, record">
        <span class="page_setPLHome">
          <span @click="setPLFn(record,'5/1秒')"
            :class='{page_setPLED: record.refresh_live_task.rate==1&&record.refresh_live_task.random==5}'
            class="page_setPL pointer">5/1秒</span>
          <span @click="setPLFn(record,'3/1秒')"
            :class='{page_setPLED: record.refresh_live_task.rate==1&&record.refresh_live_task.random==3}'
            class="page_setPL pointer">3/1秒</span>
          <span @click="setPLFn(record,'1/1秒')"
            :class='{page_setPLED: record.refresh_live_task.rate==1&&record.refresh_live_task.random==1}'
            class="page_setPL pointer">1/1秒</span>
          <span @click="setPLFn(record,'1/3秒')"
            :class='{page_setPLED: record.refresh_live_task.rate==3&&record.refresh_live_task.random==1}'
            class="page_setPL pointer">1/3秒</span>
          <span @click="setPLFn(record,'1/5秒')"
            :class='{page_setPLED: record.refresh_live_task.rate==5&&record.refresh_live_task.random==1}'
            class="page_setPL pointer">1/5秒</span>
        </span>
      </span>

      <span slot="action" slot-scope="text, record">
        <span v-show="!record.refresh_live_task.status==1">
          <a @click='begin(record)'> 开始 </a>
        </span>
        <!-- <a-switch v-model="record.refresh_live_task.status" checked-children="1" un-checked-children="0" /> -->
        <span v-show="record.refresh_live_task.status==1">
          <!-- <a-divider type="vertical" /> -->
          <a style='color:red' @click="over(record)"> 结束 </a>
        </span>
      </span>
    </a-table>
  </div>
</template> 
 
<script>

import { materialAll, delateVideo, addRefresh, refreshList, overRefresh } from '@/api/meetApi.js';
const columns = [
  {
    title: "视频直播",
    dataIndex: "video_title",
    scopedSlots: { customRender: "video_title" },
    key: "video_title",
    align: "left",
  },
  {
    title: "直播状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    key: "status",
    align: "center",
  },
  {
    title: "当前浏览量",
    dataIndex: "type",
    scopedSlots: { customRender: "type" },
    key: "type",
    align: "center",
  },
  {
    title: "目标浏览量",
    dataIndex: "setType",
    scopedSlots: { customRender: "setType" },
    key: "setType",
    align: "center",
  },
  {
    title: "刷新频率",
    key: "begin_time",
    scopedSlots: { customRender: "begin_time" },
    align: "center",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];

export default {
  name: "videoList",
  data() {
    return {
      keywordStr: '',
      loading: false,
      data: [],
      columns,
      pagination: {
        size: "large",
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ["10", "20", "30"], //可选的页面显示条数
        // onChange: page => handlePageChange(page),
        showTotal: (total, range) => {
          return "共 " + total + " 条";
        }, //展示每页第几条至第几条和总数
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器
        showQuickJumper: true, //是否可以快速跳转至某页
        showSizeChanger: true, //是否可以改变pageSize
      }
    };
  },
  methods: {
    async begin(data) {
      if (!data.refresh_live_task.target_num) {
        return this.$message.error('请输入目标浏览量！')
      }
      if (!data.refresh_live_task.random) {
        return this.$message.error('请选择刷新频率！')
      }
      if (data.refresh_live_task.target_num <= data.view) {
        return this.$message.error('目标浏览量小于货等于当前浏览量，请修改目标浏览量！')
      }
      let sendData = {
        meeting_id: this.$route.query.meeting_id,
        live_id: data.id,
        target_num: data.refresh_live_task.target_num,
        random: data.refresh_live_task.random,
        rate: data.refresh_live_task.rate,
      }
      let msg = await addRefresh(sendData)
      data.refresh_live_task.status = 1
      if (this.ws_view) {
        this.ws_view.close();
      }
      if (this.setINV) {
        clearInterval(this.setINV)
      }
      this.initWS()
      this.$forceUpdate()
    },
    async over(data) {
      // 根据live_id删除opviewList数据
      let msg = await overRefresh({ live_id: data.id, meeting_id: this.$route.query.meeting_id })
      data.refresh_live_task.status = 0
      this.$forceUpdate()
    },
    setPLFn(data, size) {
      if (data.refresh_live_task.status == 1) {
        return this.$message.error('刷新中，请先结束！')
      }
      if (size == '5/1秒') {
        data.refresh_live_task.random = 5
        data.refresh_live_task.rate = 1
      }
      if (size == '3/1秒') {
        data.refresh_live_task.random = 3
        data.refresh_live_task.rate = 1
      }
      if (size == '1/1秒') {
        data.refresh_live_task.random = 1
        data.refresh_live_task.rate = 1
      }
      if (size == '1/3秒') {
        data.refresh_live_task.random = 1
        data.refresh_live_task.rate = 3
      }
      if (size == '1/5秒') {
        data.refresh_live_task.random = 1
        data.refresh_live_task.rate = 5
      }
      this.$forceUpdate()
    },
    async getVideList(page) {
      this.SET_WEB_ROUTER(['门户', '刷新列表'])
      this.loading = true
      page.meeting_id = this.$route.query.meeting_id
      let data = await refreshList(page)
      console.log(data)
      this.data = data.payload.data
      this.loading = false
      this.pagination.total = data.payload.page.total
      this.data.forEach(item => {
        if (item.refresh_live_task && item.refresh_live_task.status == 1) {
          if (this.ws_view) {
            this.ws_view.close();
          }
          if (this.setINV) {
            clearInterval(this.setINV)
          }
          this.initWS()
          return
        }
      })
    },
    handleTableChange(data) {
      this.pagination.current = data.current
      this.getVideList({
        page: this.pagination.current
      })
    },
    initWS() {

      let _this = this
      // 实例化socket，这里的实例化直接赋值给this.ws是为了后面可以在其它的函数中也能调用websocket方法，例如：this.ws.close(); 完成通信后关闭WebSocket连接
      _this.ws_view = new WebSocket("wss://www.cndmcloud.com:9988")

      //监听是否连接成功
      _this.ws_view.onopen = () => {
        console.log('开启ws连接' + _this.ws_view.readyState);
        //连接成功则发送一个数据
        _this.ws_view.send(JSON.stringify({
          cmd: 'socket_connect',
          meeting_id: _this.$route.query.meeting_id
        }));
      }

      //接听服务器发回的信息并处理展示
      _this.ws_view.onmessage = async (data) => {
        console.log('接收到来自服务器的消息：');
        let data_view = await JSON.parse(data.data)
        if (!data_view[0]) {
          console.log('没有刷新的内容。')
          _this.ws_view.close();
          _this.data.forEach(item => { item.refresh_live_task.status = 0 })
        }
        if (data_view && data_view[0]) {
          data_view.forEach(element => {
            _this.data.forEach(item => {
              // item.refresh_live_task.status = 0
              if (item.id == element.live_id) {
                item.refresh_live_task.status = 1
                item.view = element.view

                if (item.view >= item.refresh_live_task.target_num) {
                  item.refresh_live_task.status = 0
                  // _this.getVideList({
                  //   page: this.pagination.current
                  // })
                }
                console.log(_this.data)
                _this.$forceUpdate()
              }
            })
          });
        }
        _this.setINV = setTimeout(() => {
          console.log('发送消息：');
          _this.ws_view.send(JSON.stringify({ cmd: "client_live_view", meeting_id: _this.$route.query.meeting_id }));
        }, 1000)
      }

      //监听连接关闭事件
      _this.ws_view.onclose = () => {
        //监听整个过程中websocket的状态
        console.log('关闭ws连接' + _this.ws_view.readyState);
        clearInterval(_this.setINV)
      }

      //监听并处理error事件
      _this.ws_view.onerror = function (error) {
        clearInterval(_this.setINV)
        console.log(error);
      }
    }
  },
  created() {
    this.getVideList({})
  },
  mounted() {

  },
  beforeDestroy() {
    if (this.ws_view) {
      this.ws_view.close();
    }
    if (this.setINV) {
      clearInterval(this.setINV)
    }
  }
};
</script>

<style lang="less" scoped>
.centerBox {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.page_setType {
  display: flex;
  width: 100%;
  align-items: center;
  .page_setTypeInp {
    flex: 1;
    width: 90px;
  }
}
.page_setPLHome {
  display: flex;
}
.page_setPL {
  display: flex;
  height: 36px;
  width: 50px;
  align-items: center;
  justify-content: center;
  padding: 4px 5px;
  // background-color: #e8e7e7;
  border: 1px solid #e5e6eb;
  // border-radius: 4px;
  // margin-right: 5px;
}
.page_setPL:first-child {
  border-radius: 4px 0 0 4px;
}
.page_setPL:last-child {
  border-radius: 0 4px 4px 0;
}
.page_setPLED {
  color: #1890ff;
  background: rgba(24, 144, 255, 0.1);
  border: 1px solid rgba(24, 144, 255, 0.1);
}
.page_type {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(24, 144, 255, 0.1);
  height: 36px;
  max-width: 120px;
  border-radius: 2px;
  border: 1px solid rgba(24, 144, 255, 0.1);
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #1890ff;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  padding: 0 6px;
}
:deep(.ant-table-row td) {
  padding: 8px 8px;
}
.list_img_title {
  display: flex;
  width: 100%;
  align-items: center;
  .menu_name_box {
    display: flex;
  }

  .menu_name {
    flex: 1;
    display: inline-block;
    width: 130px;
    text-align: left;
    height: 100%;
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    line-height: 20px;
  }
  img {
    width: 106px;
    height: 60px;
    border-radius: 4px;
  }
}
.addView {
  // max-width: 1400px;
  margin: auto;
  background-color: #fff;
  padding: 24px;
  margin: 0;
}

.status {
  display: flex;
  width: 62px;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  font-style: normal;
  img {
    width: 12px;
    margin-right: 2px;
  }
}
.status_wks {
  border: 1px solid #2bb840;
  color: #2bb840;
}
.status_zbz {
  border: 1px solid #1890ff;
  color: #1890ff;
}
.status_yjs {
  border: 1px solid #eb5d44;
  color: #eb5d44;
}
</style>