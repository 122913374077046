<template>
  <div class="pageA">
    <div id='home' class="pageA_title">门户——基本配置</div>
    <hr>
    <div class="pageA_data">
      <div class="pageA_left">
        <!-- <div class="pageA_Bgc_content">支持查看当前账号下所有门户/直播的访问数据，实时分析直播情况。可以按日/月/年自定义时间数据筛选。支持快速创建门户/直播等内容。</div> -->
        <div class="pageA_titleB">操作步骤</div>
        <div id="home1" class="pageA_Content">第一步：进入门户基本配置后，可以修改当前门户的公用信息。</div>
        <div class="pageA_Img"><img src="@/assets/help/jb (2).jpg" alt=""></div>
        <div id="home2" class="pageA_Content">第二步：需要注意的是，全局背景色，全局背景图，视频占位图，全局底部背景图，这四个配置项是全局的，修改后当前门户下所有页面都会生效。如下图所示，全局背景色为绿框区域，指页面最底层颜色。全局背景图为紫色框区域，指内容区域背景图片。全局底部背景图为红框区域，高度自适应，定位于页面最底部。</div>
        <div class="pageA_Img"><img src="@/assets/help/jb (1).jpg" alt=""></div>
      </div>
      <div class="pageA_right">
        <a-anchor>
          <p class="anchor_p">本页目录</p>
          <a-anchor-link href="#home1" title="基本配置" />
          <a-anchor-link href="#home2" title="页面背景" /> 
        </a-anchor>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.pageA_data {
  display: flex;
  .pageA_left {
    flex: 1;
  }
  .pageA_right {
    width: 300px;
    padding: 24px;
  }
}
.anchor_p {
  font-size: 16px;
  color: #000;
  background-color: #fff;
  padding-left: 20px;
  position: relative;
  left: -10px;
  margin-top: 20px;
}
.pageA_title {
  font-size: 30px;
  padding: 8px 0;
}
.pageA_titleB {
  margin: 30px 0;
  font-size: 20px;
}
.pageA_Content {
  margin: 30px 0;
  font-size: 14px;
  line-height: 17px;
}
.pageA_Img {
  margin: 20px 0;
  img {
    width: 100%;
  }
}
.pageA_Bgc_content {
  padding: 12px;
  margin: 24px 0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 17px;
  background-color: #f3f4f6;
}
.pageA_Img_flex {
  display: flex;
}
.pageA_Img_flexItem {
  flex: 1;
}
.pageA_Img_flexItem:first-child {
  margin-right: 10px;
}
</style>