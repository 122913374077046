<template>
  <div class="qrcodeBox">

    <div v-if="!url" class="noEwm">
      <a-icon type="loading" />
      请先输入短链接
    </div>

    <div v-if="!showLoading&&url" class="noEwm">
      <a-icon type="loading" />
      正在生成二维码，请稍后
    </div>

    <div v-show="showLoading&&url" id="qrcode" class="generalization">
    </div>

  </div>
</template>

<script>
export default {
  props: ['url'],
  data() {
    return {
      showLoading: false,
    }
  },
  methods: {
    createQRCode(data) {
      var box = document.querySelectorAll(".generalization")[0];
      box.innerHTML = '';
      if (!data) {
        return this.showLoading = false;
      }
      this.showLoading = false;
      console.log(this.showLoading && this.url)

      // 引入二维码生成库
      var qr = require('qrcode-generator');

      // 生成二维码数据
      var typeNumber = 4;
      var errorCorrectionLevel = 'L';
      var qrData = qr(typeNumber, errorCorrectionLevel);
      qrData.addData(data);
      qrData.make();

      // 获取二维码图片URL
      var qrCodeImage = qrData.createDataURL(4);

      // 创建隐藏的img元素来获取二维码图片
      var imgQR = document.createElement('img');
      imgQR.src = qrCodeImage;

      var box = document.querySelectorAll(".generalization")[0];
      box.appendChild(imgQR);

      this.showLoading = true;
      console.log(this.showLoading && this.url)
    },
    // 生成带logo的二维码
    createQRCodeTwo() {
      // 引入二维码生成库
      var qr = require('qrcode-generator');

      // 生成二维码数据
      var typeNumber = 4;
      var errorCorrectionLevel = 'L';
      var qrData = qr(typeNumber, errorCorrectionLevel);
      qrData.addData('Hello, World!');
      qrData.make();

      // 获取二维码图片URL
      var qrCodeImage = qrData.createDataURL(4);

      // 创建隐藏的img元素来获取二维码图片
      var imgQR = document.createElement('img');
      imgQR.src = qrCodeImage;
      //   document.body.appendChild(imgQR);

      // 当二维码图片加载完成后执行合并操作
      imgQR.onload = function () {
        // 创建canvas元素
        var canvas = document.createElement('canvas');
        canvas.width = imgQR.width;
        canvas.height = imgQR.height;

        // 获取画布上下文
        var ctx = canvas.getContext('2d');
        ctx.drawImage(imgQR, 0, 0, imgQR.width, imgQR.height);

        // 加载Logo图片
        var imgLogo = new Image();
        imgLogo.src = require('@/assets/日程logo.png'); // 更换为你的logo图片路径
        imgLogo.onload = function () {
          // 计算logo在二维码中的位置（居中）
          var logoWidth = imgLogo.width * 0.2; // 调整logo大小
          var logoHeight = imgLogo.height * 0.2; // 调整logo大小
          var x = (canvas.width - logoWidth) / 2;
          var y = (canvas.height - logoHeight) / 2;

          // 将logo绘制到二维码上
          ctx.drawImage(imgLogo, x, y, logoWidth, logoHeight);

          // 将含有logo的二维码导出为图片或显示在页面上
          var finalQRCodeImage = canvas.toDataURL('image/png');
          var imgFinal = document.createElement('img');
          imgFinal.src = finalQRCodeImage;

          var box = document.querySelectorAll(".generalization")[0];
          box.appendChild(imgFinal);
        };
      };
    }
  }
}
</script>

<style>
.qrcodeBox {
  width: 100%;
  height: 100%;
}
.generalization {
  width: 100%;
  height: 100%;
}
.generalization img {
  width: 100%;
  height: 100%;
}
.noEwm {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  text-align: center;
  padding: 0 24px;
}
.noEwm i {
  font-size: 36px;
  margin-bottom: 10px;
}
</style>