<template>
  <div v-if="options&&options.typeof=='文件上传'" class="baseFormBox">
    <div :style=" 'width:' + options.labelWidth + 'px' " class="baseFormBox_label"><span class="require"
        v-if="options.require" :class="{requireMsg :formwork[index].options.value}">*</span>{{options.label}}</div>
    <div class="baseFormBox_data">

      <!-- 无限制 -->
      <div v-if="options.fileType!='2'">
        <a-upload action="https://www.mocky.io/v2/5cc8019d300000980a055e76" :default-file-list="fileList">
          <a-button> <a-icon type="upload" /> 选择文件 </a-button>
        </a-upload>
      </div>
      <!-- 图片 -->
      <div v-if="options.fileType=='2'" class="clearfix">
        <a-upload action="https://www.mocky.io/v2/5cc8019d300000980a055e76" list-type="picture-card"
          :file-list="fileList" @preview="handlePreview" @change="handleChange">
          <div v-if="fileList.length < 8">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              Upload
            </div>
          </div>
        </a-upload>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
      </div>

    </div>
    <slot></slot>
  </div>
</template>

<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {},
      previewVisible: false,
      previewImage: '',
      fileList: [],
    };
  },
  props: ["index"],
  methods: {
    ...mapActions("formwork", ["SET_FORMWORKOBHFUN"]),
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
  },
  computed: {
    ...mapGetters(["formwork"]),
    options() {
      return this.formwork[this.index] ? this.formwork[this.index].options : { options: this.formwork.upload_fileOptions };
    },
    optionRules: {
      get() {
        let ruleArray = [];
        if (this.options.require) {
          ruleArray.splice(0, 0, {
            required: true,
            message: this.options.requireMsg
              ? this.options.requireMsg
              : "该内容不能为空",
            trigger: "change"
          });
        }
        return ruleArray;
      }
    },
  },
};
</script> 
<style >
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>