<template>
  <div class="pageA">
    <div id='home' class="pageA_title">首页</div>
    <hr>
    <div class="pageA_data">
      <div class="pageA_left">
        <div class="pageA_Bgc_content">支持查看当前账号下所有门户/直播的访问数据，实时分析直播情况。可以按日/月/年自定义时间数据筛选。支持快速创建门户/直播等内容。</div>
        <div class="pageA_titleB">操作步骤</div>
        <div id="home1" class="pageA_Content">第一步：登录账户后，直接进入首页界面。通过首页的功能模块，可快速创建门户直播等活动。</div>
        <div class="pageA_Img pageA_Img_flex">
          <div class="pageA_Img_flexItem"> <img src="@/assets/help/hme (1).jpg" alt=""></div>
          <div class="pageA_Img_flexItem"> <img src="@/assets/help/hme (2).jpg" alt=""></div>
        </div>
        <div id="home2" class="pageA_Content">第二步：支持筛选不同的门户活动，查看访问数据。还支持访问数据走势图展示，让运营人员对互动数据有更直观、全面地了解。</div>
        <div class="pageA_Img"><img src="@/assets/help/hme (3).jpg" alt=""></div>
        <div id="home3" class="pageA_Content">第三步：通过近期直播的操作功能，能够快速进到直播的详情，方便运营快速修改配置。</div>
        <div class="pageA_Img"><img src="@/assets/help/hme (4).jpg" alt=""></div>
      </div>
      <div class="pageA_right">
        <a-anchor>
          <p class="anchor_p">本页目录</p>
          <a-anchor-link href="#home1" title="创建功能" />
          <a-anchor-link href="#home2" title="数据展示" />
          <a-anchor-link href="#home3" title="直播操作" />
        </a-anchor>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.pageA_data {
  display: flex;
  .pageA_left {
    flex: 1;
  }
  .pageA_right {
    width: 300px;
    padding: 24px;
  }
}
.anchor_p {
  font-size: 16px;
  color: #000;
  background-color: #fff;
  padding-left: 20px;
  position: relative;
  left: -10px;
  margin-top: 20px;
}
.pageA_title {
  font-size: 30px;
  padding: 8px 0;
}
.pageA_titleB {
  margin: 30px 0;
  font-size: 20px;
}
.pageA_Content {
  margin: 30px 0;
  font-size: 14px;
  line-height: 17px;
}
.pageA_Img {
  margin: 20px 0;
  img {
    width: 100%;
  }
}
.pageA_Bgc_content {
  padding: 12px;
  margin: 24px 0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 17px;
  background-color: #f3f4f6;
}
.pageA_Img_flex {
  display: flex;
}
.pageA_Img_flexItem {
  flex: 1;
}
.pageA_Img_flexItem:first-child {
  margin-right: 10px;
}
</style>