<template>
  <div class="pageA">
    <div id='home' class="pageA_title">门户——页面配置</div>
    <hr>
    <div class="pageA_data">
      <div class="pageA_left">
        <!-- <div class="pageA_Bgc_content">支持查看当前账号下所有门户/直播的访问数据，实时分析直播情况。可以按日/月/年自定义时间数据筛选。支持快速创建门户/直播等内容。</div> -->
        <div class="pageA_titleB">操作步骤</div>
        <div id="home1" class="pageA_Content">
          第一步：进入门户页面配置后，点击【新增按钮】或者操作中【设置按钮】，可以新增/编辑页面。页面标题为前台页面的标题。页面类型有主页和内页两个标识。主页建议只设置一个。内页标识下页面右下角会有返回菜单。点击返回首页时会返回到主页标识的页面。当门户里有多个主页时，默认返回第一个主页标识页面。
        </div>
        <div class="pageA_Img pageA_Img_flex">
          <div class="pageA_Img_flexItem"> <img src="@/assets/help/ym (2).jpg" alt=""></div>
          <div class="pageA_Img_flexItem"> <img src="@/assets/help/ym (1).jpg" alt=""></div>
        </div>
        <div id="home4" class="pageA_Content">
          第二步：推广功能参考<a href="">门户列表</a>的推广功能
        </div>
        <div id="home2" class="pageA_Content">
          第三步：点击【装修】进入页面装修。左侧多个组件提供选择。只需要将组件拖拽到中间区域即可，中间区域支持上下拖拽排序。右侧为配置区域，每个组件有不同的配置项。
        </div>
        <div class="pageA_Img"><img src="@/assets/help/ymd (3).jpg" alt=""></div>
        <div id='home3' class="pageA_titleB">效果展示</div>
        <div class="pageA_Img pageA_Img_flex">
          <div class="pageA_Img_flexItem"> <img src="@/assets/help/ymd (1).jpg" alt=""></div>
          <div class="pageA_Img_flexItem" style="margin-right:10px"> <img src="@/assets/help/ymd (2).jpg" alt=""></div>
          <div class="pageA_Img_flexItem"> <img src="@/assets/help/ymd (4).jpg" alt=""></div>
        </div>
      </div>
      <div class="pageA_right">
        <a-anchor>
          <p class="anchor_p">本页目录</p>
          <a-anchor-link href="#home" title="新增页面" />
          <a-anchor-link href="#home2" title="页面配置" />
          <a-anchor-link href="#home3" title="效果展示" />
          <a-anchor-link href="#home4" title="推广" />
        </a-anchor>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.pageA_data {
  display: flex;
  .pageA_left {
    flex: 1;
  }
  .pageA_right {
    width: 300px;
    padding: 24px;
  }
}
.anchor_p {
  font-size: 16px;
  color: #000;
  background-color: #fff;
  padding-left: 20px;
  position: relative;
  left: -10px;
  margin-top: 20px;
}
.pageA_title {
  font-size: 30px;
  padding: 8px 0;
}
.pageA_titleB {
  margin: 30px 0;
  font-size: 20px;
}
.pageA_Content {
  margin: 30px 0;
  font-size: 14px;
  line-height: 17px;
}
.pageA_Img {
  margin: 20px 0;
  img {
    width: 100%;
  }
}
.pageA_Bgc_content {
  padding: 12px;
  margin: 24px 0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 17px;
  background-color: #f3f4f6;
}
.pageA_Img_flex {
  display: flex;
}
.pageA_Img_flexItem {
  flex: 1;
}
.pageA_Img_flexItem:first-child {
  margin-right: 10px;
}
</style>