<template>
  <div>
    <div class="form">
      <a-form-model :model="formData" :label-col="labelCol" :rules="rules">
        <a-form-model-item :wrapper-col="{ span: 20, offset: 1 }" prop='username' label="账户名称">
          <a-input placeholder="账户名称" v-model="formData.username" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 20, offset: 1 }" label="头像">
          <div class="round_box">
            <span class="round_up_home">
              <upload ref="uploadDelate" :image='formData.avatar_path' @uploadOk='uploadList' :showDelate='false'
                :height="'65px'" :width="'65px'" class="round_up"></upload>
            </span>
            <i style="all:unset;">300*300像素，JPG/PNG，3M</i>
            <div @click="uploadListDelate" class="delate"><a-icon type="close-circle" /></div>
          </div>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 20, offset: 1 }" label="联系人">
          <a-input placeholder="联系人姓名" v-model="formData.name" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 20, offset: 1 }" label="手机号">
          <span v-for="(item,index) in formData.mobile" :key="index">
            <span v-show="index<=2||index>=7">{{item}}</span>
            <span v-show="index>2&&index<7">*</span>
          </span>
          <span class="changeP pointer">更改</span>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 20, offset: 1 }" label="联系邮箱">
          <a-input placeholder="联系人邮箱" v-model="formData.email" />
        </a-form-model-item>
      </a-form-model>

      <div class="add_formBox_sendBox">
        <a-button @click="onSubmit" class="add_formBox_sendBox_qx">取消</a-button>
        <a-button @click="onSubmit" type="primary">
          保存
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { createUserinfo, createUserLogin } from '@/api/meetApi.js';
import Cookies from 'vue-cookies';
import upload from '@/components/upload.vue';
export default {
  props: {
    formData: {
      type: Object,
      default: {}
    }
  },
  components: { upload },
  data() {
    return {
      labelCol: { span: 3 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入账号名称', trigger: 'blur' },
          { min: 0, max: 50, message: '最大长度50字', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    uploadList(str) {
      if (str.status == 1) {
        this.formData.avatar_path = str.payload.file_url
        this.formData.avatar = str.payload.id
      } else {
        alert('上传失败，请刷新重试！')
      }
    },
    uploadListDelate() {
      this.formData.avatar_path = ''
      this.formData.avatar = ''
      this.$refs.uploadDelate.delate()
    },
    async onSubmit() {
      if (!this.formData.username) {
        return this.$message.error('请输入账户名称！')
      }
      let data = await createUserinfo(this.formData)
      this.$store.state.loginInfo.userinfo = this.formData
      sessionStorage.setItem('userinfo', JSON.stringify(this.formData));
      Cookies.set('userinfo', this.formData);
      this.$message.success('修改成功！')
      console.log('submit!', data);
    },
  },
};
</script>
<style lang="less" scoped>
.add_formBox_sendBox {
  flex: 1;
  border-top: 1px solid #e3e4e8;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  position: fixed;
  width: calc(100% - 204px);
  bottom: 12px;
  left: 192px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.05);
  text-align: center;
  align-items: center;
}
.add_formBox_sendBox_qx {
  margin-right: 10px;
}
.changeP {
  font-weight: 400;
  font-size: 14px;
  color: #1890ff;
  margin-left: 8px;
}
.title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
.form {
  padding-top: 8px;
  width: calc(480px + 148px);
  min-height: calc(100vh - 235px);
  max-width: 1024px;
}

:deep(.round_up .ant-upload) {
  width: 85px;
  height: 85px;
  position: relative;
  overflow: hidden;
  border-radius: 85px;
}

:deep(.round_up .ant-upload img) {
  width: 85px;
  height: 85px;
  position: absolute;
  border-radius: 85px;
  left: 0;
  top: 0;
  max-width: unset;
  max-height: unset;
}

.round_box {
  .round_up_home {
    display: block;
    margin-bottom: 5px;
    width: 85px;
    height: 85px;
  }
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #86909d;
  position: relative;
  .delate {
    position: absolute;
    left: 85px;
  }
}
.round_box:hover {
  .delate {
    opacity: 1;
  }
}
</style>