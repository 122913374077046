<template>
  <div v-if="showNum" class="modal_2">
    <!-- <div class="modal_2"> -->
    <div class="add add_pof">
      <div :class="{h625: checkMenu_click}" class="add_form add_pof">

        <div class="add_form_title">
          推广
          <a-icon @click="close" class="add_form_close" type="close" />
        </div>

        <div class="checkMenu">
          <span @click='checkMenu_clickFn(false)' :class="{checkMenu_click: !checkMenu_click}"
            class="checkMenu_item pointer">链接与二维码<span v-show='!checkMenu_click' class="line_checkMenu"></span></span>
          <span @click='checkMenu_clickFn(true)' :class="{checkMenu_click: checkMenu_click}"
            class="checkMenu_item pointer">微信分享<span v-show='checkMenu_click' class="line_checkMenu"></span></span>
        </div>

        <div v-show="!checkMenu_click" class="add_formBox">
          <div class="add_formBox_item">
            <div class="add_formBox_name">分享链接</div>
            <div class="add_formBox_inp">
              <!-- 门户推广时显示短链接输入。其他的全部禁用输入功能 -->
              <a-input v-if="sendForm.promotion_type=='meeting'" class="w480" @change="showEwm"
                :addon-before="windLink+'meeting/'" v-model="value" placeholder="短链接" />
              <a-input v-if="sendForm.promotion_type=='view'" class="w480" disabled v-model="value" placeholder="短链接" />
              <a-input v-if="sendForm.promotion_type=='live'" class="w480" disabled v-model="value" placeholder="短链接" />
              <a-input v-if="sendForm.promotion_type=='document'" class="w480" disabled v-model="value"
                placeholder="短链接" />
              <input class="copyValue" v-model="value" type="text">
            </div>
            <a-button @click="copy" class="add_formBox_btn">复制</a-button>
            <a-button @click="open" class="add_formBox_btn">打开</a-button>
          </div>

          <div class="ewm_img">
            <div class="ewm_img_name">二维码</div>
            <div class="ewm_img_box">
              <div class="img_ewm">
                <generalizationBox ref="generalizationBox" :url='value'></generalizationBox>
              </div>
              <!-- <a-button class="ewm_img_formBox_btn">
                <a href="@/assets/icon_home@2x.png" download="@/assets/icon_home@2x.png">下载二维码</a>
              </a-button> -->
            </div>
          </div>

        </div>

        <div v-show="checkMenu_click" class="add_formBox add_formBoxTwo">
          <div class="share_top">

            <div v-show="share_F==1" class="share_left">
              <img width="100%" src="@/assets/cs_001.jpg" alt="">
              <div class="share_left_item">
                <div class="bg_left_share">
                  <img src="@/assets/cs_003.png" alt="">
                  <div class="share_message_bg">
                    <div class="share_message_title text2">{{sendForm.wechat_share_title}}<span
                        v-if="!sendForm.wechat_share_title">请输入分享标题</span></div>
                    <div class="share_message_desc">
                      <div class="share_desc text3">{{sendForm.wechat_share_content}}<span
                          v-if="!sendForm.wechat_share_content">请输入分享简介</span></div>
                      <div class="bg_left_logo">
                        <span class="noLog" v-if="!sendForm.wechat_share_img_path">上传图片</span>
                        <img v-if="sendForm.wechat_share_img_path" width="100%" height="100%"
                          :src="sendForm.wechat_share_img_path" alt="">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg_left_right">
                  <div class="bg_left_logo">
                    <img width="100%" height="100%" src="@/assets/logo/logo01.jpg" alt="">
                  </div>
                </div>
              </div>
            </div>

            <div v-show="share_F==2" class="share_left">
              <img width="100%" src="@/assets/share_bg.png" alt="">
              <div class="logo_name">
                <span>数会云</span>
                <div class="logo_name_G"><img width="100%" height="100%" src="@/assets/logo/logo01.jpg" alt=""></div>
              </div>

              <div class="share_Q">
                <div class="share_Q_logoBox">
                  <div class="share_Q_logo"><img width="100%" height="100%" src="@/assets/logo/logo01.jpg" alt=""></div>
                </div>
                <div class="share_Q_messageBox">
                  <div class="share_Q_messageTietle">数会云</div>
                  <div class="share_Q_messageContent">
                    <div class="share_Q_logo">
                      <span class="noLog" v-if="!sendForm.moments_share_img_path">上传图片</span>
                      <img width="100%" v-if="sendForm.moments_share_img_path" height="100%"
                        :src="sendForm.moments_share_img_path" alt="">
                    </div>
                    <div class="share_Q_messageTxt text2">{{sendForm.moments_share_title}}
                      {{sendForm.moments_share_content?' | '+sendForm.moments_share_content:''}}<span
                        v-if="!sendForm.moments_share_title">请输入分享标题</span></div>
                  </div>
                  <div class="share_Q_messagebottom">
                    1分钟前
                    <span>· ·</span>
                  </div>
                </div>
              </div>

            </div>

            <div class="share_right">
              <a-radio-group v-model="share_F" @change="onChange">
                <a-radio :value="1">
                  微信好友
                </a-radio>
                <a-radio :value="2">
                  朋友圈
                </a-radio>
              </a-radio-group>

              <div v-if="share_F==1">
                <div class="share_title">
                  <div class="share_title_base">分享标题</div>
                  <div class="share_title_inp"><a-input v-model="sendForm.wechat_share_title" placeholder="请输入" /></div>
                </div>
                <div class="share_title">
                  <div class="share_title_base">分享描述</div>
                  <div class="share_title_inp"><a-input v-model="sendForm.wechat_share_content" placeholder="请输入" />
                  </div>
                </div>
                <div class="share_title">
                  <div class="share_title_base">分享icon</div>
                  <div class="share_title_inp">
                    <div class="up_box">
                      <upload key="wechat_share_img_path" id="wechat_share_img_path" v-if="share_F==1"
                        :image='sendForm.wechat_share_img_path' @uploadOk='uploadOk3' :width="'60px'" :showDelate='true'
                        @delateDate='delateDate3'></upload>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="share_F==2">
                <div class="share_title">
                  <div class="share_title_base">分享标题</div>
                  <div class="share_title_inp"><a-input v-model="sendForm.moments_share_title" placeholder="请输入" />
                  </div>
                </div>
                <div class="share_title">
                  <div class="share_title_base">分享描述</div>
                  <div class="share_title_inp"><a-input v-model="sendForm.moments_share_content" placeholder="请输入" />
                  </div>
                </div>
                <div class="share_title">
                  <div class="share_title_base">分享icon</div>
                  <div class="share_title_inp">
                    <div class="up_box">
                      <upload key="moments_share_img_path" id="moments_share_img_path" v-if="share_F==2"
                        :image='sendForm.moments_share_img_path' @uploadOk='uploadOkT3' :width="'60px'"
                        :showDelate='true' @delateDate='delateDateT3'></upload>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>

        <div class="share_bot">
          <a-button @click="close">取消</a-button>
          <a-button @click='sendFormFn' type="primary">
            确定
          </a-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>   
import {
  promotionDetail,
  promotionCreate
} from '@/api/meetApi.js';
import generalizationBox from './generalization.vue';
import upload from './upload.vue';
export default {
  components: { upload, generalizationBox },
  data() {
    return {
      share_F: 1,
      checkMenu_click: false,
      showNum: false,
      windLink: 'https://www.cndmcloud.com/',
      value: '',
      sendForm: {
        custom_link: '', //自定义链接			      
        wechat_share_title: '分享标题', //微信分享标题		 
        wechat_share_content: '分享描述', //微信分享描述	 
        wechat_share_img: '', //微信分享图片id		 
        wechat_share_img_path: '', //微信分享图片		 
        moments_share_title: '分享标题', //朋友圈分享标题	 
        moments_share_content: '分享描述', //朋友圈分享描述 
        moments_share_img: '', //朋友圈分享图片id	 
        moments_share_img_path: '', //朋友圈分享图片	 
      }
    };
  },
  methods: {
    uploadOk3(data) {
      this.sendForm.wechat_share_img = data.payload.id
      this.sendForm.wechat_share_img_path = data.payload.path
      console.log(data)
    },
    delateDate3() {
      this.sendForm.wechat_share_img = ''
      this.sendForm.wechat_share_img_path = ''
    },
    uploadOkT3(data) {
      this.sendForm.moments_share_img = data.payload.id
      this.sendForm.moments_share_img_path = data.payload.path
      console.log(data)
    },
    delateDateT3() {
      this.sendForm.moments_share_img = ''
      this.sendForm.moments_share_img_path = ''
    },
    onChange() { },
    checkMenu_clickFn(data) {
      this.checkMenu_click = data
    },
    copy() {
      if (!this.value) {
        return this.$message.error("请先输入短链接")
      }
      let data = document.querySelectorAll(`.copyValue`)[0]


      if (this.sendForm.promotion_type == 'view') {
        data.value = this.value
      } else if (this.sendForm.promotion_type == 'live') {
        data.value = this.value
      } else {
        data.value = this.windLink + 'meeting/' + this.value
      }

      data.select()
      document.execCommand("copy");
      this.$message.success("复制成功");
    },
    async show(type, id) {
      let data = await promotionDetail({ promotion_type: type, id: id })
      this.sendForm = data.payload.data
      if (this.sendForm.promotion_type == 'view') {
        this.value = this.windLink + 'home/#/?view_id=' + this.sendForm.id + '&meeting_id=' + this.$route.query.meeting_id
      } else if (this.sendForm.promotion_type == 'live') {
        if (this.$route.query.meeting_id) {
          this.value = this.windLink + 'home/#/videoIng?meeting_id=' + this.$route.query.meeting_id + '&id=' + this.sendForm.id
        } else {
          this.value = this.windLink + 'home/#/videoIng?id=' + this.sendForm.id
        }
      } else if (this.sendForm.promotion_type == 'document') {
        if (this.$route.query.meeting_id) {
          this.value = this.windLink + 'home/#/article?meeting_id=' + this.$route.query.meeting_id + '&id=' + this.sendForm.id
        } else {
          this.value = this.windLink + 'home/#/article?id=' + this.sendForm.id
        }
      } else {
        this.value = data.payload.data.custom_link
      }

      this.showNum = true

      let _this = this
      setTimeout(() => {

        if (_this.sendForm.promotion_type == 'view') {
          _this.$refs.generalizationBox.createQRCode(_this.value)
        } else if (_this.sendForm.promotion_type == 'live') {
          _this.$refs.generalizationBox.createQRCode(_this.value)
        } else if (_this.sendForm.promotion_type == 'document') {
          _this.$refs.generalizationBox.createQRCode(_this.value)
        } else {
          _this.$refs.generalizationBox.createQRCode(_this.windLink + 'homepage/meeting/' + _this.sendForm.id)
        }

      }, 100)
    },
    showEwm() {
      this.$refs.generalizationBox.createQRCode(this.windLink + 'homepage/meeting/' + this.sendForm.id)
    },
    open() {
      if (this.sendForm.promotion_type == 'view') {
        window.open(this.value)
      } else if (this.sendForm.promotion_type == 'live') {
        window.open(this.value)
      } else {
        window.open(this.windLink + 'homepage/meeting/' + this.sendForm.id)
      }
    },
    close(e) {
      this.checkMenu_click = false
      this.showNum = false
      this.share_F = 1
    },
    async sendFormFn() {
      if (!this.value) {
        return this.$message.error("短链接必填")
      }
      this.sendForm.custom_link = this.value
      let data = await promotionCreate(this.sendForm)
      this.$message.success('保存成功！')
      this.close()
    }
  }
}
</script>

<style lang="less" scoped>
* {
  word-break: break-all;
}
.noLog {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid #7f7f7f;
  font-weight: 400;
  font-size: 6px;
  color: #7f7f7f;
  line-height: 8px;
  text-align: center;
  font-style: normal;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
}
.share_Q_messagebottom {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 9px;
  color: #b2b2b2;
  line-height: 12px;
  text-align: left;
  font-style: normal;
  margin-top: 2px;
  span {
    display: inline-block;
    width: 19px;
    height: 12px;
    background: #f7f7f7;
    border-radius: 2px;
    border-radius: 2px;
    font-size: 14px;
    text-align: center;
  }
}
.img_ewm {
  width: 120px;
  height: 120px;
}
.share_Q {
  margin-top: 20px;
  display: flex;
  padding: 0 16px;
}
.share_Q_logoBox {
  margin-right: 6px;
  width: 26px;
  .share_Q_logo {
    border-radius: 4px;
    overflow: hidden;
    width: 26px;
    height: 26px;
    background-color: #fff;
    border-radius: 4px;
  }
}
.share_Q_messageBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  .share_Q_messageContent {
    height: 30px;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    padding: 0 3px;
    .share_Q_logo {
      background-color: #fff;
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
    .share_Q_messageTxt {
      flex: 1;
      font-weight: 400;
      font-size: 9px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 12px;
      text-align: left;
      font-style: normal;
    }
  }
  .share_Q_messageTietle {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 11px;
    color: #5b6a92;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    margin-bottom: 2px;
  }
}
.logo_name {
  display: flex;
  align-items: center;
  justify-content: right;
  transform: translateY(-70%);
  margin-right: 6px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 13px;
  color: #fefbf2;
  line-height: 17px;
  text-align: right;
  font-style: normal;
  span {
    transform: translateY(-8px);
  }
}
.logo_name_G {
  overflow: hidden;
  border-radius: 4px;
  width: 42px;
  height: 42px;
  background-color: #fff;
  margin-left: 8px;
}
.share_desc {
  font-size: 10px;
  margin-top: 5px;
  color: #b3b3b3;
}
.share_message_desc {
  position: relative;
  display: flex;
  text-align: justify;
  padding-right: 35px;
  .bg_left_logo {
    border-radius: 2px;
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 2px;
    right: 5px;
    top: 4px;
    bottom: 0;
    margin: auto;
  }
}
.share_message_title {
  width: 100%;
  max-height: 36px;
  overflow: hidden;
  text-align: justify;
  padding-right: 5px;
}
.share_message_bg {
  position: absolute;
  top: 0;
  width: calc(100% - 2px);
  min-height: 75px;
  font-size: 12px;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  padding-right: 0;
  margin-right: 2px;
}
.share_left_item {
  position: absolute;
  right: 7px;
  top: 50px;
  width: 188px;
  min-height: 72px;
  display: flex;
  .bg_left_right {
    flex: 1;
    img {
      width: 100%;
    }
    .bg_left_logo {
      border-radius: 4px;
      overflow: hidden;
      width: 25px;
      height: 25px;
      background-color: #fff;
      border-radius: 2px;
    }
  }
  .bg_left_share {
    position: relative;
    width: 157px;
    min-height: 72px;
    margin-right: 3px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.up_box {
  width: 80px;
  height: 80px;
}
.share_title_base {
  margin-bottom: 10px;
}
.share_title {
  margin-top: 20px;
}
.share_top {
  display: flex;
}
.share_bot {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}
.share_left {
  position: relative;
  overflow: hidden;
  width: 255px;
  height: 455px;
  background-color: #fff;
  border: 1px solid #ebecf0;
  margin-right: 12px;
  button {
    margin-left: 12px;
  }
}
.share_right {
  flex: 1;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
}
.add_pof {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 999;
}
.add {
  background-color: rgba(0, 0, 0, 0.6);
}
.add_form {
  width: 800px;
  height: 430px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  bottom: 0;
  top: 0;
}

.h625 {
  height: 625px;
}
.add_form_title {
  display: flex;
  justify-content: space-between;
  height: 46px;
  background: #f3f6f9;
  padding: 0 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  .add_form_close {
    color: #818181;
    display: inline-block;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 3px;
  }
}
.add_formBox {
  padding: 20px 0px 20px 0px;
  margin: 0 20px;
  flex: 1;
}

.add_formBoxTwo {
  padding: 21px 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}
.add_formBox_item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eff0f2;
  padding-bottom: 20px;
}
.add_formBox_name {
  text-align: right;
  padding-right: 16px;
  width: 100px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 0;
}
.add_formBox_inp {
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  flex: 1;
  margin: 0 10px 0 0px;
}
.add_formBox_btn {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  margin-right: 16px;
  color: #1890ff;
}
.add_formBox_btn:last-child {
  margin-right: 20px;
}
.ewm_img {
  display: flex;
  padding-top: 20px;
}
.ewm_img_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 120px;
    height: 120px;
    margin-bottom: 12px;
  }
}
.ewm_img_formBox_btn {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: #585858;
}
.ewm_img_name {
  text-align: right;
  padding-right: 16px;
  width: 100px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
}
.add_formBox_inp {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  overflow: hidden;
}
.copyValue {
  opacity: 0;
  position: absolute;
  height: 0;
  z-index: -5;
  overflow: hidden;
}
.add_form_close:hover {
  color: #000;
  font-weight: 600;
}
.checkMenu {
  height: 46px;
  border-bottom: 1px solid #eff0f2;
  display: flex;
}
.checkMenu_item {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
  text-align: center;
  font-style: normal;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;
}
.line_checkMenu {
  height: 2px;
  border-radius: 2px;
  width: 1em;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #1890ff;
}
.checkMenu_click {
  color: #1890ff;
}
</style>

<style>
.add_formBox_time .ant-calendar-picker-input {
  padding-left: 38px;
}
.add_formBox_time .anticon {
  display: none;
}
.share_bot button {
  margin-left: 12px;
}
.ant-radio-wrapper {
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
</style>