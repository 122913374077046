<template>

  <div class="video_set">

    <div class="dataMsg">
      <div class="video_set_title">图文配置</div>
      <div class="dataBox">

        <div class="page">

          <!-- 左侧页面效果展示 -->
          <div class="page_l">
            <div ref='model_P' class="model">
              <div class="phone_title_home">
                <img width="100%" src="@/assets/phone_title.png" alt="">
                <div class="phone_title_txt text">{{form.title}}</div>
              </div>
              <div class="articleBase">
                <div class="articleBase_title">{{form.title}}</div>
                <div v-if="form.author" class="color_art articleBase_address">作者：{{form.author}}</div>
                <div class="color_art articleBase_time">
                  <span>发布时间：{{publish_time/1000|time('YYYY-MM-DD HH:mm:ss')}}</span>
                  <span><img src="@/assets/auto_img/view@2x (2).png" alt=""> {{form.view}}</span>
                </div>
                <div class="articleBase_content" v-html="form.content"></div>
              </div>
            </div>
          </div>
          <!-- 基本信息配置 -->
          <div v-if="form.id" class="page_c">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-form-model-item ref="title" label="图文标题" prop="title">
                <a-input size="large" v-model="form.title" @blur=" () => { $refs.title.onFieldBlur(); } " />
              </a-form-model-item>
              <a-form-model-item label="发布时间" prop="publish_time">
                <a-date-picker size="large" format="YYYY-MM-DD HH:mm:ss"
                  :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" placeholder="请输入发布时间"
                  v-model="publish_time" style="width: 100%;" />
              </a-form-model-item>
              <a-form-model-item ref="author" label="作者" prop="author">
                <a-input size="large" placeholder="请输入作者" v-model="form.author"
                  @blur=" () => { $refs.author.onFieldBlur(); } " />
              </a-form-model-item>
              <a-form-model-item ref="view" label="观看人数" prop="view">
                <div class="number_btn_home">
                  <div @click="number_inp(-10)" class="number_btn pointer">-</div>
                  <a-input class="number_inp" v-model="form.view" size="large"
                    @blur=" () => { $refs.view.onFieldBlur(); } " />
                  <div @click="number_inp(10)" class="number_btn pointer">+</div>
                </div>
              </a-form-model-item>
              <a-form-model-item ref="listCover" label="封面" prop="listCover">
                <div class="video_list_img">
                  <div class="video_list_imgUp">
                    <upload :image='form.cover_img_path' @uploadOk='uploadCover' :showDelate='true'
                      @delateDate='uploadCoverDelate' :height="'70px'"> </upload>
                  </div>
                </div>
              </a-form-model-item>
              <a-form-model-item label="页面样式" prop="is_enable_page_style">
                <div>
                  <a-switch :default-checked='form.is_enable_page_style==1' @change="is_sign_in_onChange" />
                  <span class="pat_is_enable_page_style">开启后可定义页面背景</span>
                </div>
                <div @change='changeMobileBG' v-if="form.is_enable_page_style==1">
                  <a-radio-group v-model="form.page_style">
                    <a-radio v-if="meeting_data&&meeting_data.id" :value="1">
                      使用全局背景色
                    </a-radio>
                    <a-radio v-if="meeting_data&&meeting_data.id" :value="2">
                      使用全局背景图
                    </a-radio>
                    <a-radio v-if="meeting_data&&meeting_data.id" :value="3">
                      使用全局底部背景图
                    </a-radio>
                    <a-radio :value="4">
                      图片
                    </a-radio>
                  </a-radio-group>
                </div>
                <div v-if="form.is_enable_page_style==1&&form.page_style==4" class="video_list_img">
                  <div class="video_list_imgUp">
                    <upload :image='form.page_style_img_path' @uploadOk='uploadList' :showDelate='true'
                      @delateDate='uploadListDelate' :height="'70px'"> </upload>
                  </div>
                </div>
              </a-form-model-item>

              <a-form-model-item ref="listCover" label="图文内容" prop="listCover">
                <div class="video_list_Editor">
                  <Editor :form='editor' @input='input($event)' key="articleEditor"></Editor>
                </div>
              </a-form-model-item>
              <div class="send_box_video">
                <a-button type="primary" @click="onSubmit">
                  保存
                </a-button>
                <a-button style="margin-left: 10px;" @click="resetForm">
                  重置
                </a-button>
              </div>
            </a-form-model>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import { createDocument, getDocumentList, getDocumentDet } from '@/api/meetApi.js';
import upload from '@/components/upload.vue';
import Editor from '@/components/Editor.vue';
import moment from "moment";

export default {
  components: { upload, Editor },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      form: {},
      meeting_data: {},
      publish_time: '',
      editor: { data: { desc: '' } },
      rules: {
        title: [{ required: true, message: '请输入标题' }],
        publish_time: [{ required: true, message: '请选择发布时间', }],
      },
    };
  },
  created() {
    this.getBaseForm()
  },
  methods: {
    moment,
    async getBaseForm() {
      let data = await getDocumentDet({ id: this.$route.query.id })
      this.form = data.payload.data
      this.meeting_data = data.payload.meeting_data
      this.publish_time = this.moment(this.form.publish_time * 1000)
      this.changeMobileBG()
      this.editor = { data: { desc: this.form.content } }
    },
    input(data) {
      this.form.content = data
    },
    uploadList(str) {
      if (str.status == 1) {
        this.form.page_style_img_path = str.payload.path
        this.form.page_style_img = str.payload.id
        this.changeMobileBG()
      } else {
        alert('上传失败，请刷新重试！')
      }
    },
    uploadListDelate() {
      this.form.page_style_img_path = ''
      this.form.page_style_img = ''
    },
    uploadCover(str) {
      if (str.status == 1) {
        this.form.cover_img_path = str.payload.path
        this.form.cover_img = str.payload.id
        this.changeMobileBG()
      } else {
        alert('上传失败，请刷新重试！')
      }
    },
    uploadCoverDelate() {
      this.form.cover_img_path = ''
      this.form.cover_img = ''
    },
    async onSubmit() {
      this.form.publish_time = this.moment(this.publish_time).format('X')
      let data = await createDocument(this.form)
      this.$message.success('保存成功！')
      console.log(data)
    },
    resetForm() {
      this.$router.go(0)
    },
    number_inp(num) {
      this.form.view = this.form.view + num
    },
    is_sign_in_onChange(checked, event) {
      if (checked) {
        this.form.is_enable_page_style = 1
      } else {
        this.form.is_enable_page_style = 2
      }
      this.changeMobileBG()
    },
    changeMobileBG() {
      let domPhone = this.$refs.model_P
      if (this.form.is_enable_page_style == 2) {
        domPhone.style.background = '#FFF'
        return
      }
      if (this.form.page_style == 1) {
        domPhone.style.background = this.meeting_data.background_color
      } else if (this.form.page_style == 2) {
        domPhone.style.background = "url(" + this.meeting_data.background_img_path + ") ";
        domPhone.style.backgroundRepeat = 'no-repeat'
        domPhone.style.backgroundSize = '100% 100%'
      } else if (this.form.page_style == 3) {
        domPhone.style.background = "url(" + this.meeting_data.tail_img_path + ") ";
        domPhone.style.backgroundRepeat = 'no-repeat'
        domPhone.style.backgroundSize = '100% auto'
        domPhone.style.backgroundPosition = 'bottom'
      } else if (this.form.page_style == 4) {
        domPhone.style.background = "url(" + this.form.page_style_img_path + ") ";
        domPhone.style.backgroundRepeat = 'no-repeat'
        domPhone.style.backgroundSize = '100% 100%'
      }
    }
  },
}
</script>

<style lang="less" scoped>
.pat_is_enable_page_style {
  font-weight: 400;
  font-size: 13px;
  color: #86909d;
  line-height: 18px;
  position: relative;
  top: 1px;
  margin-left: 12px;
  text-align: left;
}
.articleBase {
  padding: 15px 20px;
  .articleBase_title {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 12px;
    color: #222222;
    line-height: 25px;
  }
  .color_art {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 4px;
    color: #999999;
  }
  .articleBase_time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #eff0f2;
  }
  img {
    width: 16px;
  }
}
:deep(.ant-form-item-control) {
  max-width: 500px;
}
.video_list_Editor {
  width: 620px;
  height: 433px;
}
.send_box_video {
  position: fixed;
  bottom: 10px;
  width: calc(100% - 204px);
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.05);
  left: 192px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video_list_img {
  height: 90px;
  .video_list_imgUp {
    width: 160px;
    height: 90px;
  }
}
.number_btn_home {
  display: flex;
  width: 160px;
  .number_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f7fa;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid #e5e6eb;
    width: 40px;
    height: 40px;
  }
  .number_inp {
    flex: 1;
    border-radius: 0px;
    text-align: center;
  }
  .number_btn:last-child {
    border-radius: 0px 4px 4px 0px;
  }
}
.video_set_title {
  padding: 12px 24px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 18px;
  color: #222222;
  line-height: 25px;
  text-align: left;
  font-style: normal;
}
.video_set {
  // max-width: 1400px;
  margin: auto;
  padding: 0 12px;
  margin-bottom: 12px;
  // margin: 0 20px 20px 20px;
}
.titleBox {
  height: 60px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  padding: 0 18px;
  div {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
  }
}
.status {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  padding: 3px 5px;
  border-radius: 4px;
  margin-left: 5px;
}
.status_begin {
  background: rgb(232, 234, 237);
  color: rgb(153, 153, 153);
}
.status_doing {
  background: rgba(62, 247, 71, 0.5);
  color: rgb(249, 250, 251);
}
.status_end {
  background: rgba(251, 2, 2, 0.5);
  color: rgb(251, 252, 253);
}

.dataMsg {
  margin-top: 12px;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.fl1 {
  flex: 1;
}
.mr20 {
  margin-right: 20px;
}
.page {
  padding: 0 24px;
  display: flex;
  height: calc(100vh - 122px);
  padding-right: 0;
}
.page_l {
  padding-bottom: 60px;
  position: relative;
  margin-top: 12px;
  // flex: 1;
  // background-color: #f7f9fc;
  display: flex;
  justify-content: center;
  overflow: auto;
  .model {
    position: relative;
    // margin-top: 20px;
    width: 375px;
    max-height: 802px;
    height: calc(100% - 24px);
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #e5e6eb;
    margin-bottom: 24px;
  }
}
.page_c {
  margin-top: 24px;
  overflow: auto;
  padding-bottom: 60px;
  flex: 1;
  form {
    margin-right: 24px;
  }
}
</style>
<style>
.page_c .ant-radio-wrapper {
  margin-bottom: 8px;
  margin-top: 5px;
}

.articleBase_content {
  word-break: break-all;
  text-align: justify;
}
.articleBase_content img {
  max-width: 100% !important;
}
.articleBase_content {
  margin-top: 16px;
}
</style>