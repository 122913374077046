<template>
  <div class="pageA">
    <div id='home' class="pageA_title">门户列表</div>
    <hr>
    <div class="pageA_data">
      <div class="pageA_left">
        <!-- <div class="pageA_Bgc_content">支持查看当前账号下所有门户/直播的访问数据，实时分析直播情况。可以按日/月/年自定义时间数据筛选。支持快速创建门户/直播等内容。</div> -->
        <div class="pageA_titleB">操作步骤</div>
        <div id="home1" class="pageA_Content">第一步：登录后台之后，点击左侧菜单面板的【门户】进入门户列表界面。点击右上角【创建门户】，输入门户名称。点击【配置】按钮跳转到门户详情。</div>
        <div class="pageA_Img pageA_Img_flex">
          <div class="pageA_Img_flexItem"> <img src="@/assets/help/mh (1).jpg" alt=""></div>
          <div class="pageA_Img_flexItem"> <img src="@/assets/help/mh.jpg" alt=""></div>
        </div>
        <div id="home2" class="pageA_Content">第二步：点击【推广】，查看推广链接和二维码。支持一键复制链接，或者打开链接。</div>
        <div class="pageA_Img"><img src="@/assets/help/mh (3).jpg" alt=""></div>
        <div id="home3" class="pageA_Content">第三步：点击【推广】中【微信分享】按钮，配置微信分享信息，支持聊天分享配置和朋友圈分享配置。</div>
        <div class="pageA_Img pageA_Img_flex">
          <div class="pageA_Img_flexItem"> <img src="@/assets/help/mh (5).jpg" alt=""></div>
          <div class="pageA_Img_flexItem"> <img src="@/assets/help/mh (6).jpg" alt=""></div>
        </div>
      </div>
      <div class="pageA_right">
        <a-anchor>
          <p class="anchor_p">本页目录</p>
          <a-anchor-link href="#home1" title="新增门户" />
          <a-anchor-link href="#home2" title="推广" />
          <a-anchor-link href="#home3" title="分享" />
        </a-anchor>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.pageA_data {
  display: flex;
  .pageA_left {
    flex: 1;
  }
  .pageA_right {
    width: 300px;
    padding: 24px;
  }
}
.anchor_p {
  font-size: 16px;
  color: #000;
  background-color: #fff;
  padding-left: 20px;
  position: relative;
  left: -10px;
  margin-top: 20px;
}
.pageA_title {
  font-size: 30px;
  padding: 8px 0;
}
.pageA_titleB {
  margin: 30px 0;
  font-size: 20px;
}
.pageA_Content {
  margin: 30px 0;
  font-size: 14px;
  line-height: 17px;
}
.pageA_Img {
  margin: 20px 0;
  img {
    width: 100%;
  }
}
.pageA_Bgc_content {
  padding: 12px;
  margin: 24px 0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 17px;
  background-color: #f3f4f6;
}
.pageA_Img_flex {
  display: flex;
}
.pageA_Img_flexItem {
  flex: 1;
}
.pageA_Img_flexItem:first-child {
  margin-right: 10px;
}
</style>