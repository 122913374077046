<template>
  <div class="pageA">
    <div id='home' class="pageA_title">门户——直播配置</div>
    <hr>
    <div class="pageA_data">
      <div class="pageA_left">
        <!-- <div class="pageA_Bgc_content">支持查看当前账号下所有门户/直播的访问数据，实时分析直播情况。可以按日/月/年自定义时间数据筛选。支持快速创建门户/直播等内容。</div> -->
        <div class="pageA_titleB">操作步骤</div>
        <div id="home1" class="pageA_Content">
          第一步：进入门户页面配置后，点击【新增直播】或者操作中【配置】，可以新增/编辑直播。页面类型，选择直播时为播放m3u8格式直播。选择议程时点击可查看海报。选择视频时播放MP4格式视频。外联点击时直接跳转。
        </div>
        <div class="pageA_Img pageA_Img_flex">
          <div class="pageA_Img_flexItem"> <img src="@/assets/help/zb (1).jpg" alt=""></div>
          <div class="pageA_Img_flexItem"> <img src="@/assets/help/zb (2).jpg" alt=""></div>
        </div>
        <div id="home2" class="pageA_Content">
          第二步：推广功能参考<a @click="changeHome('1-0-0')">门户列表</a>的推广功能
        </div>
        <div id="home3" class="pageA_Content">
          第三步：点击配置进入直播配置后，总共可进行四项配置。分别是基本，多频道，Tab，签到。基本配置，可以配置直播标题，封面图，播放地址，是否开启签到。当开启多语言时，直播页将切换为英文频道。
        </div>
        <div class="pageA_Img"><img src="@/assets/help/zb.jpg" alt=""></div>
        <div id="home4" class="pageA_Content">
          多频道打开时，直播界面将显示多频道列表。可以配置多个频道，每个频道可配置频道名称等内容。另外通过选择多频道可以实现中英文切换。
        </div>
        <div class="pageA_Img"><img src="@/assets/help/zb (3).jpg" alt=""></div>
        <div id="home5" class="pageA_Content">
          不启用多Tab时，此处可配置底部海报内容。当启用多Tab时，底部将显示Tab列表。可以配置多个Tab名称和海报等内容。
        </div>
        <div class="pageA_Img"><img src="@/assets/help/zb (4).jpg" alt=""></div>
        <div id="home6" class="pageA_Content">
          签到配置，需要在基本配置中打开签到功能。可以配置签到标题，签到表单。
        </div>
        <div class="pageA_Img"><img src="@/assets/help/zb (5).jpg" alt=""></div>

      </div>
      <div class="pageA_right">
        <a-anchor>
          <p class="anchor_p">本页目录</p>
          <a-anchor-link href="#home" title="新增直播" />
          <a-anchor-link href="#home2" title="推广" />
          <a-anchor-link href="#home3" title="基本配置" />
          <a-anchor-link href="#home4" title="多频道" />
          <a-anchor-link href="#home5" title="Tab" />
          <a-anchor-link href="#home6" title="签到" /> 
        </a-anchor>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    changeHome(data) {
      this.$emit('changeHome', data)
    }
  }
}
</script>

<style lang="less" scoped>
.pageA_data {
  display: flex;
  .pageA_left {
    flex: 1;
  }
  .pageA_right {
    width: 300px;
    padding: 24px;
  }
}
.anchor_p {
  font-size: 16px;
  color: #000;
  background-color: #fff;
  padding-left: 20px;
  position: relative;
  left: -10px;
  margin-top: 20px;
}
.pageA_title {
  font-size: 30px;
  padding: 8px 0;
}
.pageA_titleB {
  margin: 30px 0;
  font-size: 20px;
}
.pageA_Content {
  margin: 30px 0;
  font-size: 14px;
  line-height: 17px;
}
.pageA_Img {
  margin: 20px 0;
  img {
    width: 100%;
  }
}
.pageA_Bgc_content {
  padding: 12px;
  margin: 24px 0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 17px;
  background-color: #f3f4f6;
}
.pageA_Img_flex {
  display: flex;
}
.pageA_Img_flexItem {
  flex: 1;
}
.pageA_Img_flexItem:first-child {
  margin-right: 10px;
}
</style>